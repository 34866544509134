import { EventCategories } from '@features/analytics/models/logEvents'

export type ProfilingSubmitQAndA = { [id: string]: string }

export enum ProfilingMarketingStatesEnum {
  PRESENTED = 'presented',
  COMPLETED = 'completed',
}

export const ProfilingQuestionReportValues = {
  industry: 'userIndustryV1',
  brandName: 'brandNameV1',
  userType: 'userTypeV1',
  teamSize: 'teamSizeV1',
  timeInBusiness: 'timeActive',
  profilingRank: 'profiling_rank',
}

export enum ProfilingQuestionKeysEnum {
  INDUSTRY = 'industry',
  TYPE = 'type',
  TEAM_SIZE = 'team_size',
  BRAND_TEXT = 'brand_text',
  BRAND_SELECT = 'brand_select',
  BRAND = 'brand',
  BRAND_SELECT_TEXT = 'brand_select_text',
  TIME_IN_BUSINESS = 'time_in_business',
  PROFILING_DOWNLINE = 'downline',
  PROFILING_DOWNLINE_SIZE = 'downline_size',

  // ------------------- DOWN-LINE ------------------------
  PROFILING_DOWNLINE_HERBALIFE = 'downline_herbalife',
  PROFILING_DOWNLINE_FARMASI = 'downline_farmasi',
  PROFILING_DOWNLINE_YOUNIQUE = 'downline_younique',
  PROFILING_DOWNLINE_ARBONNE = 'downline_arbonne',
  PROFILING_DOWNLINE_NU_SKIN = 'downline_nu_skin',
  PROFILING_DOWNLINE_IT_WORKS = 'downline_it_works',
  PROFILING_DOWNLINE_MODERE = 'downline_modere',
  PROFILING_DOWNLINE_JUICE_PLUS = 'downline_juice_plus',
  PROFILING_DOWNLINE_PAMPERED_CHEF = 'downline_pampered_chef',
  PROFILING_DOWNLINE_XYNGULAR = 'downline_xyngular',
  PROFILING_DOWNLINE_ORIFLAME = 'downline_oriflame',
  PROFILING_DOWNLINE_PRUVIT = 'downline_pruvit',
  PROFILING_DOWNLINE_MARY_KAY = 'downline_mary_key',
  PROFILING_DOWNLINE_SENE_GENCE = 'downline_sene_gence',
  PROFILING_DOWNLINE_MONAT = 'downline_monat',
  PROFILING_DOWNLINE_DO_TERRA = 'downline_do_terra',
  PROFILING_DOWNLINE_LIMELIFE = 'downline_limelife',
  PROFILING_DOWNLINE_MELALEUCA = 'downline_melaleuca',
  PROFILING_DOWNLINE_SCENTSY = 'downline_scentsy',
  PROFILING_DOWNLINE_PURE_ROMANCE = 'downline_pure_romance',
  PROFILING_DOWNLINE_TRANONT = 'downline_tranont',
  PROFILING_DOWNLINE_TUPEERWARE = 'downline_tupeerware',
  PROFILING_DOWNLINE_COLOR_STREET = 'downline_color_street',
  PROFILING_DOWNLINE_THIRTY_ONE_GIFTS = 'downline_one_gifts',

  // ----------------------- RANK -------------------------
  PROFILING_RANK_HERBALIFE = 'profiling_rank_herbalife',
  PROFILING_RANK_FARMASI = 'profiling_rank_farmasi',
  PROFILING_RANK_YOUNIQUE = 'profiling_rank_younique',
  PROFILING_RANK_ARBONNE = 'profiling_rank_arbonne',
  PROFILING_RANK_NU_SKIN = 'profiling_rank_nu_skin',
  PROFILING_RANK_IT_WORKS = 'profiling_rank_it_works',
  PROFILING_RANK_MODERE = 'profiling_rank_modere',
  PROFILING_RANK_JUICE_PLUS = 'profiling_rank_juice_plus',
  PROFILING_RANK_PAMPERED_CHEF = 'profiling_rank_pampered_chef',
  PROFILING_RANK_XYNGULAR = 'profiling_rank_xyngular',
  PROFILING_RANK_ORIFLAME = 'profiling_rank_oriflame',
  PROFILING_RANK_PRUVIT = 'profiling_rank_pruvit',
  PROFILING_RANK_MARY_KAY = 'profiling_rank_mary_key',
  PROFILING_RANK_SENE_GENCE = 'profiling_rank_sene_gence',
  PROFILING_RANK_MONAT = 'profiling_rank_monat',
  PROFILING_RANK_DO_TERRA = 'profiling_rank_do_terra',
  PROFILING_RANK_LIMELIFE = 'profiling_rank_limelife',
  PROFILING_RANK_MELALEUCA = 'profiling_rank_melaleuca',
  PROFILING_RANK_SCENTSY = 'profiling_rank_scentsy',
  PROFILING_RANK_PURE_ROMANCE = 'profiling_rank_pure_romance',
  PROFILING_RANK_TRANONT = 'profiling_rank_tranont',
  PROFILING_RANK_TUPEERWARE = 'profiling_rank_tupeerware',
  PROFILING_RANK_COLOR_STREET = 'profiling_rank_color_street',
  PROFILING_RANK_THIRTY_ONE_GIFTS = 'profiling_rank_one_gifts',
}

export const ProfilingQuestionReportKeys: { [key: string]: string } = {
  [ProfilingQuestionKeysEnum.INDUSTRY]: ProfilingQuestionReportValues.industry,
  [ProfilingQuestionKeysEnum.BRAND]: ProfilingQuestionReportValues.brandName,
  [ProfilingQuestionKeysEnum.BRAND_TEXT]:
    ProfilingQuestionReportValues.brandName,
  [ProfilingQuestionKeysEnum.BRAND_SELECT]:
    ProfilingQuestionReportValues.brandName,
  [ProfilingQuestionKeysEnum.TYPE]: ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.TEAM_SIZE]: ProfilingQuestionReportValues.teamSize,
  [ProfilingQuestionKeysEnum.BRAND_SELECT_TEXT]:
    ProfilingQuestionReportValues.brandName,
  [ProfilingQuestionKeysEnum.TIME_IN_BUSINESS]:
    ProfilingQuestionReportValues.timeInBusiness,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SIZE]:
    ProfilingQuestionReportValues.teamSize,

  // ------------------- DOWN-LINE ------------------------
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_HERBALIFE]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_FARMASI]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_YOUNIQUE]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_ARBONNE]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_NU_SKIN]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_IT_WORKS]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MODERE]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_JUICE_PLUS]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_PAMPERED_CHEF]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_XYNGULAR]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_ORIFLAME]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_PRUVIT]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MARY_KAY]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SENE_GENCE]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MONAT]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_DO_TERRA]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_LIMELIFE]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MELALEUCA]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SCENTSY]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_PURE_ROMANCE]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_TRANONT]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_TUPEERWARE]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_COLOR_STREET]:
    ProfilingQuestionReportValues.userType,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_THIRTY_ONE_GIFTS]:
    ProfilingQuestionReportValues.userType,

  // ----------------------- RANK -------------------------
  [ProfilingQuestionKeysEnum.PROFILING_RANK_HERBALIFE]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_FARMASI]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_YOUNIQUE]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_ARBONNE]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_NU_SKIN]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_IT_WORKS]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_MODERE]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_JUICE_PLUS]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_PAMPERED_CHEF]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_XYNGULAR]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_ORIFLAME]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_PRUVIT]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_MARY_KAY]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_SENE_GENCE]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_MONAT]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_DO_TERRA]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_LIMELIFE]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_MELALEUCA]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_SCENTSY]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_PURE_ROMANCE]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_TRANONT]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_TUPEERWARE]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_COLOR_STREET]:
    ProfilingQuestionReportValues.profilingRank,
  [ProfilingQuestionKeysEnum.PROFILING_RANK_THIRTY_ONE_GIFTS]:
    ProfilingQuestionReportValues.profilingRank,
}

export const ProfilingQuestionEventNames = {
  [ProfilingQuestionKeysEnum.INDUSTRY]: EventCategories.APP_PROFILING_INDUSTRY,
  [ProfilingQuestionKeysEnum.BRAND_TEXT]:
    EventCategories.APP_PROFILING_BRAND_TEXT,
  [ProfilingQuestionKeysEnum.BRAND_SELECT]:
    EventCategories.APP_PROFILING_BRAND_SELECT,
  [ProfilingQuestionKeysEnum.TYPE]: EventCategories.APP_PROFILING_TYPE,
  [ProfilingQuestionKeysEnum.BRAND]: EventCategories.APP_PROFILING_BRAND_SELECT,
  [ProfilingQuestionKeysEnum.TEAM_SIZE]:
    EventCategories.APP_PROFILING_TEAM_SIZE,
  [ProfilingQuestionKeysEnum.BRAND_SELECT_TEXT]:
    EventCategories.APP_PROFILING_BRAND_SELECT_TEXT,
  [ProfilingQuestionKeysEnum.TIME_IN_BUSINESS]:
    EventCategories.APP_PROFILING_TIME_IN_BUSINESS,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SIZE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_SIZE,

  // ------------------- DOWN-LINE ------------------------
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_HERBALIFE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_FARMASI]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_YOUNIQUE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_ARBONNE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_NU_SKIN]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_IT_WORKS]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MODERE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_JUICE_PLUS]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_PAMPERED_CHEF]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_XYNGULAR]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_ORIFLAME]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_PRUVIT]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MARY_KAY]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SENE_GENCE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MONAT]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_DO_TERRA]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_LIMELIFE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MELALEUCA]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SCENTSY]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_PURE_ROMANCE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_TRANONT]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_TUPEERWARE]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_COLOR_STREET]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,
  [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_THIRTY_ONE_GIFTS]:
    EventCategories.APP_PROFILING_PROFILING_DOWNLINE_RANK,

  // ----------------------- RANK -------------------------
  [ProfilingQuestionKeysEnum.PROFILING_RANK_HERBALIFE]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_FARMASI]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_YOUNIQUE]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_ARBONNE]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_NU_SKIN]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_IT_WORKS]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_MODERE]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_JUICE_PLUS]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_PAMPERED_CHEF]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_XYNGULAR]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_ORIFLAME]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_PRUVIT]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_MARY_KAY]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_SENE_GENCE]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_MONAT]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_DO_TERRA]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_LIMELIFE]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_MELALEUCA]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_SCENTSY]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_PURE_ROMANCE]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_TRANONT]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_TUPEERWARE]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_COLOR_STREET]: 'profiling_rank',
  [ProfilingQuestionKeysEnum.PROFILING_RANK_THIRTY_ONE_GIFTS]: 'profiling_rank',
}

export const firstQuestion = ProfilingQuestionKeysEnum.INDUSTRY
export const answerLeader = 'leader'
