import { QueryDocumentSnapshot, QuerySnapshot } from 'firebase/firestore'
import { BoardItem } from '../../../app/models'
import { AppDispatch } from '../../../app/store'
import { boardActions, permissionActions } from '../../../actions'

import { transformOwnBoard } from '../transformers/OwnBoardTransformer'
import { transformReceivedBoard } from '../transformers/ReceivedBoardTransformer'
import { transformPermission } from '../transformers/PermissionTransformer'

const setPermissionReceivedBoard = ({
  boardId,
  doc,
  dispatch,
}: {
  doc: QueryDocumentSnapshot
  dispatch: AppDispatch
  boardId: string
}) => {
  const data = transformPermission(doc)
  const permission = { [data.recipient]: data }
  dispatch(permissionActions.setPermission(boardId, permission, true))
}

export const BoardsSnapshot =
  (
    own: boolean,
    onSnapshot: (own: boolean, boardIds: string[]) => void,
    onAdd: (b: BoardItem) => void,
    onRemove: (boardId: string) => void,
    dispatch: AppDispatch,
  ) =>
  (snapshot: QuerySnapshot) => {
    const boardIds: string[] = []

    snapshot.docChanges().forEach(({ doc, type }) => {
      const board = own ? transformOwnBoard(doc) : transformReceivedBoard(doc)

      if (type === 'added') {
        onAdd(board)
        boardIds.push(board.boardId)
      }

      if (['added', 'modified'].includes(type) && !own) {
        setPermissionReceivedBoard({ doc, dispatch, boardId: board.boardId })
      }

      if (type === 'removed') {
        dispatch(boardActions.removeBoard(board.boardId))
        onRemove(board.boardId)
      }
    })

    onSnapshot(own, boardIds)
  }
