import { Trans } from 'react-i18next'
import { ContentIcon, Grid, Icon, Tab, TabList, ThemeIcon } from 'boards-web-ui'

import { TabsEnum } from '@features/editorPages/hooks/useTabState'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import Chip from '@ui/components/Chip'

import UndoRedo from '../../toolbar/components/UndoRedo'

import styles from './ContentHead.module.css'

const ContentHead = () => {
  const { profile, hideThemePickerTabHint } = useProfileInformation()

  const handleOnClick = () => {
    if (!profile.uiState.themePickerTabHintShown) {
      hideThemePickerTabHint()
    }
  }

  return (
    <div className={styles.Root}>
      <Grid columnGap={0}>
        <TabList>
          <Tab value={TabsEnum.CONTENT}>
            <Grid columnGap={5}>
              <Icon size="20px" color="inherit">
                <ContentIcon />
              </Icon>
              <Trans i18nKey="page_builder_tab_content" />
            </Grid>
          </Tab>
          <Tab value={TabsEnum.THEME_PICKER} onClick={handleOnClick}>
            <Grid columnGap={5}>
              <Icon size="20px" color="inherit">
                <ThemeIcon />
              </Icon>
              <Trans i18nKey="page_builder_tab_theme" />
            </Grid>
          </Tab>
        </TabList>
        {!profile.uiState.themePickerTabHintShown && (
          <Grid>
            <Chip blue>
              <Trans i18nKey={'badge_new'} />
            </Chip>
          </Grid>
        )}
      </Grid>
      <UndoRedo />
    </div>
  )
}

export default ContentHead
