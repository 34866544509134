import { getCurrencySymbol } from 'boards-web-ui'

const getCurrencyInputLabel = (prefix: string, currency: string): string => {
  const currencySymbol = getCurrencySymbol(currency)
  return (
    prefix + (currencySymbol.length ? ` (${currencySymbol})` : ` (${currency})`)
  )
}

export default getCurrencyInputLabel
