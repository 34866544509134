import { FC } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { ErrorIndicator, ErrorIndicatorSize, If } from 'boards-web-ui'

import InfoTipBanner from '@features/editorPages/components/InfoTipBanner'
import ListItems from '@features/editorPages/components/ListItems'
import PageMenu from '@features/editorPages/components/PageMenu'
import ScrollArea from '@ui/components/Scroll/ScrollArea'

import { Folder, Node } from 'app/models'
import { ScrollAreaContainer } from '@ui/models/ScrollArea'
import useTipBanner from '@features/editorPages/hooks/useTipBanner'
import { useSelectorFactory } from '@hooks/useSelectorFactory'
import useProfileFeatureFlag from '@hooks/useProfileFeatureFlag'
import { ProfileFeatures } from '@models/UserProfile'
import {
  ICreateOrUpdateNode,
  INodeProps,
} from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import useRouterParams from '@hooks/useRouterParams'
import { SelectAction } from '@features/pickers'
import useBoardMode from '@features/board/hooks/useBoardMode'
import { selectMissedPageFields } from 'selectors'

import styles from './PageEditor.module.css'

interface Props {
  folder: Folder
  shouldShowIndicators: boolean
  showPremiumPreviewPageLimitationModeBanner: boolean
  createNode: ICreateOrUpdateNode
  updateNode: (id: string, node: INodeProps) => void
  selectAction: SelectAction
  deleteNode?: (node: Node) => void
}
const PageBuilderTools: FC<Props> = ({
  folder,
  shouldShowIndicators,
  showPremiumPreviewPageLimitationModeBanner,
  createNode,
  updateNode,
  selectAction,
  deleteNode,
}) => {
  const mode = useBoardMode()
  const { boardId } = useRouterParams()
  const { t } = useTranslation()
  const { isTipBannerVisible, handleTemplateBannerDismiss } =
    useTipBanner(folder)
  const { isFeatureAvailable: isCheckoutFeatureAvailable } =
    useProfileFeatureFlag(ProfileFeatures.CHECKOUT)

  const missedPageProperties = useSelectorFactory(
    selectMissedPageFields,
    boardId,
    folder?.id,
    isCheckoutFeatureAvailable,
  )

  return (
    <>
      <If state={shouldShowIndicators}>
        <ErrorIndicator size={ErrorIndicatorSize.MEDIUM}>
          <span>{t('info_missing_page_banner')}</span>&nbsp;
          <span className={styles.Bold}>
            {t('info_missing_page_banner_see_below_placeholder')}
          </span>
        </ErrorIndicator>
      </If>

      <div
        className={clsx(
          styles.EditorContent,
          showPremiumPreviewPageLimitationModeBanner &&
            styles.EditorContentWithBanner,
        )}
        style={{ height: '100%', display: 'flex' }}
      >
        <PageMenu
          folder={folder}
          createNode={createNode}
          updateNode={updateNode}
          selectAction={selectAction}
        />

        <ScrollArea className={styles.Content} area={ScrollAreaContainer.PAGE}>
          <>
            {isTipBannerVisible && (
              <div className={styles.TipBanner}>
                <InfoTipBanner
                  icon="💡"
                  title={t('page_editor_template_message_title')}
                  description={t('page_editor_template_message_body')}
                  onDismiss={handleTemplateBannerDismiss}
                />
              </div>
            )}

            <ListItems
              folder={folder}
              mode={mode}
              updateNode={updateNode}
              deleteNode={deleteNode}
              missedPageProperties={missedPageProperties}
            />
          </>
        </ScrollArea>
      </div>
    </>
  )
}

export default PageBuilderTools
