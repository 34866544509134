import { useDispatch } from 'react-redux'
import { useDialog } from 'boards-web-ui'

import { randomContentId } from '@helpers/index'
import { useState } from 'react'
import { putFile } from '../../../../_firebase'
import { uploadActions } from '../../../../actions'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'
import useAddContentTranslations from '../../toolbar/hooks/useAddContentTranslations'
import useUpload from '../../toolbar/hooks/useUpload'

import EditLinkPdf from '../components/EditLinkPdf'
import { IUseAddPDF } from '../models/IUseAddNode'
import { NodeType } from '../../../models'

const MAX_UPLOAD_FILE_SIZE = 40000

const preventExitWhileLoading = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = false
}

const useAddPdf: IUseAddPDF = (save, isPageEditor, meta) => {
  const dispatch = useDispatch()
  const { open } = useDialog()
  const { bigFile } = useAddContentTranslations()

  const [updatedPdf, setUpdatedPdf] = useState({ text: '', title: '' })

  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()

  const uploadPdf = async (file: HTMLInputElement) => {
    if (!isApiAvailableOrNotify()) return

    window.addEventListener('beforeunload', preventExitWhileLoading)

    const size = Math.round(file.size / 1024)
    if (size >= MAX_UPLOAD_FILE_SIZE) {
      // eslint-disable-next-line
      alert(bigFile())
      return
    }

    const name = randomContentId()
    const baseName = `${process.env.REACT_APP_PDF_URL}/${name}`
    const fileName = `${name}.pdf`
    const fileUrl = encodeURI(`${baseName}/${file.name}`)

    dispatch(uploadActions.addUpload(fileUrl))
    await putFile(file, fileName)
    dispatch(uploadActions.removeUpload(fileUrl))

    window.removeEventListener('beforeunload', preventExitWhileLoading)

    // eslint-disable-next-line
    return {
      title: file.name.slice(0, -4),
      text: `${baseName}.pdf`,
    }
  }

  const editPdf = ({ title, text }: { title: string; text: string }) => {
    open(
      <EditLinkPdf initialText={text} initialTitle={title} onSave={save} />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  const addPdfNode = async (file: HTMLInputElement) => {
    const upload = await uploadPdf(file)
    if (upload) {
      const { title, text } = upload

      if (meta?.isEditMode) {
        setUpdatedPdf({ title, text })

        save({ title, text, type: NodeType.PDF, thumbnail: undefined })

        if (meta.onContentReplaceEvent) {
          meta.onContentReplaceEvent()
        }

        return
      }

      if (!isPageEditor) {
        editPdf({
          title,
          text,
        })
        return
      }

      save(
        {
          title,
          text,
          type: NodeType.PDF,
        },
        {},
      )
    }
  }

  const addPdf = useUpload(addPdfNode, {
    accept: 'application/pdf',
    multiple: false,
  })

  return { addPdf, updatedPdf }
}

export default useAddPdf
