import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { InputPrice } from 'boards-web-ui'

import getCurrencyInputLabel from '@features/checkout/utils/getCurrencyInputLabel'

import { useCheckout } from '../hooks'

import ConnectPayPalButton from './ConnectPayPalButton'
import styles from './CheckoutSettingsPaymentInformation.module.css'

const CheckoutSettingsPaymentInformation: FC = () => {
  const { t } = useTranslation()
  const {
    isNew,
    isSubmitted,
    currency,
    shippingFees,
    minOrder,
    isPaypalFormConnected,
    onPaypalConnect,
    onPaypalDisconnect,
    onShippingFeesChange,
    onMinOrderChange,
    isPayPalConnectionPending,
    isPayPalDisconnectedPending,
    merchantPaymentsReceivable,
    merchantPrimaryEmailConfirmed,
  } = useCheckout()

  const errorMessage = useCallback((): string => {
    if (isPayPalConnectionPending) {
      return ''
    }

    if (isNew) {
      return isSubmitted && !isPaypalFormConnected
        ? t('info_missing_connect_payment_provider')
        : ''
    }

    return !isPaypalFormConnected
      ? t('info_missing_connect_payment_provider')
      : ''
  }, [isNew, isSubmitted, t, isPaypalFormConnected, isPayPalConnectionPending])

  return (
    <>
      <div className={styles.Prices}>
        <InputPrice
          hideCurrencySymbol
          value={shippingFees}
          currency={currency}
          minValue="0"
          label={getCurrencyInputLabel(t('label_shipping_fees'), currency)}
          onChange={(e) => onShippingFeesChange(e.target.value)}
        />
        <InputPrice
          hideCurrencySymbol
          value={minOrder}
          currency={currency}
          minValue="0"
          label={getCurrencyInputLabel(t('label_min_order'), currency)}
          onChange={(e) => onMinOrderChange(e.target.value)}
        />
      </div>
      <div className={styles.PaymentProvider}>
        <span className={styles.ButtonLabel}>
          {t('label_payment_provider')}
        </span>

        <ConnectPayPalButton
          isPayPalConnectionPending={isPayPalConnectionPending}
          isPayPalDisconnectedPending={isPayPalDisconnectedPending}
          errorMessage={errorMessage()}
          isPaypalFormConnected={isPaypalFormConnected}
          merchantPaymentsReceivable={merchantPaymentsReceivable}
          merchantPrimaryEmailConfirmed={merchantPrimaryEmailConfirmed}
          onDisconnect={onPaypalDisconnect}
          onConnect={onPaypalConnect}
        />
      </div>
    </>
  )
}

export default React.memo(CheckoutSettingsPaymentInformation)
