import { FC } from 'react'

import CloseButton from '@ui/components/CloseButton'

import styles from './InfoTipBanner.module.css'

interface Props {
  icon?: string
  title?: string
  description: string
  onDismiss?: () => void
}
const InfoTipBanner: FC<Props> = ({ icon, title, description, onDismiss }) => {
  return (
    <div className={styles.Root}>
      {icon && <div className={styles.LightbulbTipIcon}>{icon}</div>}
      <div className={styles.Content}>
        {title && <span className={styles.Title}>{title}</span>}
        <span className={styles.Description}>{description}</span>
      </div>

      {onDismiss && (
        <CloseButton className={styles.CloseBtn} onClose={onDismiss} />
      )}
    </div>
  )
}

export default InfoTipBanner
