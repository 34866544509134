import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  useLocation,
  unstable_HistoryRouter as HistoryRouter,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  useNavigate,
} from 'react-router-dom'
import { Provider, useDispatch } from 'react-redux'
import { theme } from 'boards-web-ui'

import {
  init as initSentry,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react'

import { useAuth } from '@hooks/useAuth'
import { useUpdateUserInfo } from '@hooks/useUpdateUserInfo'
import useSkipFirstChange from '@hooks/useSkipFirst'
import useEmailAuthenticationCheck from '@hooks/useEmailAuthenticationCheck'
import useIdentifyAnalyticsUser from '@hooks/useIdentifyAnalyticsUser'
import useGeoLocation from '@hooks/useGeoLocation'
import { syncAuthState } from '@features/cromeExtension/api'
import defaultTheme from 'defaultTheme'

import FirebaseAuthProvider from './_firebase/providers/FirebaseAuthProvider'
import FirebaseListenersProvider from './_firebase/providers/FirebaseListenersProvider'

import CustomerIO from './ui/components/CustomerIO'
import { ENV_KEYS } from './utils/envKeys'
import { appcuesSetUserIdentity, appcuesReloadPage } from './utils/appcues'
import { configurationObjectFromEnv } from './utils/configurationObjectFromEnv'

import store, { history } from './app/store'
import TagManager from './utils/gtm'
import Router from './app/routes'
import config from './app/features/cromeExtension/config'

import './translations/i18n'
import './css/index.css'
import './css/Normalize.css'
import './css/App.css'
import { UNIVERSAL_LINK_KEEP_IN_WEB_SYMBOL } from './app/constants/routes'

const queryClient = new QueryClient()

interface EnvProps {
  gtmId: string
  nodeEnv: 'development' | 'production'
  sentryDSN: string
  firebaseContentFunctionsUrl: string
  firebaseGeneralFunctionsUrl: string
}
const {
  gtmId,
  nodeEnv,
  sentryDSN,
  firebaseContentFunctionsUrl,
  firebaseGeneralFunctionsUrl,
} = configurationObjectFromEnv<EnvProps>({
  gtmId: ENV_KEYS.GTM_ID,
  nodeEnv: ENV_KEYS.NODE_ENV,
  sentryDSN: ENV_KEYS.SENTRY_DSN,
  firebaseContentFunctionsUrl: ENV_KEYS.FIREBASE_CONTENT_FUNCTIONS_URL,
  firebaseGeneralFunctionsUrl: ENV_KEYS.FIREBASE_CONTENT_FUNCTIONS_URL,
})

if (nodeEnv === 'production') {
  TagManager.initialize({ gtmId })

  initSentry({
    dsn: sentryDSN,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      firebaseContentFunctionsUrl,
      firebaseGeneralFunctionsUrl,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const AppWrapper: React.FC = () => {
  const dispatch = useDispatch()
  const { user, loaded } = useAuth()
  const { pathname, search, hash } = useLocation()
  const navigate = useNavigate()
  const { loadGeoData } = useGeoLocation()

  useUpdateUserInfo(user)

  const { identified } = useIdentifyAnalyticsUser(user)

  useEmailAuthenticationCheck(user, loaded)

  useSkipFirstChange(() => {
    return syncAuthState(config.id, () => user)
  }, [user])

  useEffect(() => {
    appcuesReloadPage()
  }, [pathname])

  useEffect(() => {
    if (user) {
      appcuesSetUserIdentity(user.uid, { email: user.email })
    }
  }, [user])

  /**
   * Removes the dollar ($) from the path and navigate to the same URL excluding
   * $ sign. Context: Any route starting with /$/ should not open Mobile app
   * (Universal Links feature)
   */
  useEffect(() => {
    if (pathname.startsWith(UNIVERSAL_LINK_KEEP_IN_WEB_SYMBOL)) {
      const newPath = pathname.replace(UNIVERSAL_LINK_KEEP_IN_WEB_SYMBOL, '/')
      const finalUrl = `${newPath}${search}${hash}`
      navigate(finalUrl, { replace: true })
    }
  }, [pathname, navigate, search, hash])

  return (
    <FirebaseListenersProvider
      user={user}
      dispatch={dispatch}
      onLoad={loadGeoData}
    >
      <theme.UiThemeProvider theme={{ webPageTheme: defaultTheme }}>
        <Router identifiedUser={identified} />
      </theme.UiThemeProvider>
    </FirebaseListenersProvider>
  )
}

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <CustomerIO />
      <FirebaseAuthProvider>
        {/* @ts-expect-error: https://github.com/remix-run/react-router/issues/9630#issuecomment-1341643731 */}
        <HistoryRouter history={history}>
          <QueryClientProvider client={queryClient}>
            <theme.UiThemeProvider>
              <AppWrapper />
            </theme.UiThemeProvider>
          </QueryClientProvider>
        </HistoryRouter>
      </FirebaseAuthProvider>
    </Provider>,
  )
}
