import { TFunction } from 'i18next'
import {
  answerLeader,
  ProfilingQuestionKeysEnum,
} from '@features/profiling/models'
import {
  RankAnswersArbonne,
  RankAnswersColorStreet,
  RankAnswersDoTerra,
  RankAnswersFarmasi,
  RankAnswersHerbalife,
  RankAnswersItWorks,
  RankAnswersJuicePlus,
  RankAnswersLimelife,
  RankAnswersMaryKay,
  RankAnswersMelaleuca,
  RankAnswersModere,
  RankAnswersMonat,
  RankAnswersNuSkin,
  RankAnswersOriflame,
  RankAnswersPamperedChef,
  RankAnswersPruvit,
  RankAnswersPureRomance,
  RankAnswersScentsy,
  RankAnswersSeneGence,
  RankAnswersThirtyOneGifts,
  RankAnswersTranont,
  RankAnswersTupeerware,
  RankAnswersXyngular,
  RankAnswersYounique,
  TeamSizeAnswers,
  TimeAnswers,
} from '@features/profiling/data/profilingAnswers'

export interface OptionProp {
  value: string
  label: string
  next?: ProfilingQuestionKeysEnum
}

export const getIndustryOptions = (translations: TFunction): OptionProp[] => [
  {
    label: translations('profiling_industry_network'),
    value: '🚀 Network Marketing',
    next: ProfilingQuestionKeysEnum.BRAND,
  },
  {
    label: translations('profiling_industry_direct_sales'),
    value: '🎯 Direct Sales',
    next: ProfilingQuestionKeysEnum.BRAND,
  },
  {
    label: translations('profiling_industry_realestate'),
    value: '🏢 Real Estate',
    next: ProfilingQuestionKeysEnum.BRAND_TEXT,
  },
  {
    label: translations('profiling_industry_automotive'),
    value: '🚗 Automotive',
    next: ProfilingQuestionKeysEnum.BRAND_TEXT,
  },
  {
    label: translations('profiling_industry_influencer'),
    value: '🤳 Influencer Marketing',
    next: ProfilingQuestionKeysEnum.BRAND_TEXT,
  },
  {
    label: translations('profiling_industry_ecommerce'),
    value: '🛍️ E-commerce',
    next: ProfilingQuestionKeysEnum.BRAND_TEXT,
  },
  {
    label: translations('profiling_industry_multi_marketing'),
    value: '🏖️ Multi-level Marketing',
    next: ProfilingQuestionKeysEnum.BRAND,
  },
  {
    label: translations('action_other'),
    value: 'Other',
    next: ProfilingQuestionKeysEnum.BRAND_TEXT,
  },
]

export const getBrandOptions = (translations: TFunction): OptionProp[] => [
  {
    label: 'Herbalife',
    value: 'Herbalife',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_HERBALIFE,
  },
  {
    label: 'It Works',
    value: 'It Works',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_IT_WORKS,
  },
  {
    label: 'Younique',
    value: 'Younique',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_YOUNIQUE,
  },
  {
    label: 'Tranont',
    value: 'Tranont',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_TRANONT,
  },
  {
    label: 'Pampered Chef',
    value: 'Pampered Chef',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_PAMPERED_CHEF,
  },
  {
    label: 'Xyngular',
    value: 'Xyngular',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_XYNGULAR,
  },
  {
    label: 'Modere',
    value: 'Modere',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MODERE,
  },
  {
    label: 'doTerra',
    value: 'doTerra',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_DO_TERRA,
  },
  {
    label: 'Nu Skin',
    value: 'Nu Skin',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_NU_SKIN,
  },
  {
    label: 'Ringana',
    value: 'Ringana',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Mary Kay',
    value: 'Mary Kay',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MARY_KAY,
  },
  {
    label: 'SeneGence',
    value: 'SeneGence',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SENE_GENCE,
  },
  {
    label: 'Pruvit',
    value: 'Pruvit',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_PRUVIT,
  },
  {
    label: 'Arbonne',
    value: 'Arbonne',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_ARBONNE,
  },
  {
    label: 'Juice Plus',
    value: 'Juice Plus',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_JUICE_PLUS,
  },
  {
    label: 'Oriflame',
    value: 'Oriflame',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_ORIFLAME,
  },
  {
    label: 'Lorde and Belle',
    value: 'Lorde and Belle',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Farmasi',
    value: 'Farmasi',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_FARMASI,
  },
  {
    label: 'Limelife',
    value: 'Limelife',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_LIMELIFE,
  },
  {
    label: 'Jordan Essentials',
    value: 'Jordan Essentials',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Tupeerware',
    value: 'Tupeerware',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_TUPEERWARE,
  },
  {
    label: 'Color Street',
    value: 'Color Street',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_COLOR_STREET,
  },
  {
    label: 'Thirty One Gifts',
    value: 'Thirty One Gifts',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_THIRTY_ONE_GIFTS,
  },
  {
    label: 'Scentsy',
    value: 'Scentsy',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SCENTSY,
  },
  {
    label: 'Young Living',
    value: 'Young Living',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Monat',
    value: 'Monat',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MONAT,
  },
  {
    label: 'Melaleuca',
    value: 'Melaleuca',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MELALEUCA,
  },
  {
    label: 'Epicure',
    value: 'Epicure',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: 'Isagenix',
    value: 'Isagenix',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
  {
    label: translations('action_other'),
    value: 'Other',
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
  },
]

export const getTimeInBusinessOptions = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_time_in_bussines_3_months'),
    value: TimeAnswers.o0_3,
  },
  {
    label: translations('profiling_time_in_bussines_12_months'),
    value: TimeAnswers.o4_12,
  },
  {
    label: translations('profiling_time_in_bussines_3_years'),
    value: TimeAnswers.o12_36,
  },
  {
    label: translations('profiling_time_in_bussines_4_years'),
    value: TimeAnswers.o48,
  },
]

export const getTypeOptions = (translations: TFunction): OptionProp[] => [
  {
    label: translations('profiling_who_are_you_option_team_leader'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.TEAM_SIZE,
  },
  {
    label: translations('profiling_who_are_you_option_part_team'),
    value: 'member',
  },
  {
    label: translations('profiling_who_are_you_option_individual'),
    value: 'individual',
  },
]

export const getTeamSizeOptions = (): OptionProp[] => [
  { label: '1-10', value: TeamSizeAnswers.o1_10 },
  { label: '11-50', value: TeamSizeAnswers.o11_50 },
  { label: '51-100', value: TeamSizeAnswers.o51_100 },
  { label: '101-200', value: TeamSizeAnswers.o101_200 },
  { label: '201-300', value: TeamSizeAnswers.o201_300 },
  { label: '301-500', value: TeamSizeAnswers.o301_500 },
  { label: '501+', value: TeamSizeAnswers.o501 },
]

export const getProfilingDownline = (translations: TFunction): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SIZE,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]

export const getProfilingDownlineSize = (): OptionProp[] => [
  {
    label: '1-10',
    value: TeamSizeAnswers.o1_10,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '11-50',
    value: TeamSizeAnswers.o11_50,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '51-100',
    value: TeamSizeAnswers.o51_100,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '101-200',
    value: TeamSizeAnswers.o101_200,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '201-300',
    value: TeamSizeAnswers.o201_300,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '301-500',
    value: TeamSizeAnswers.o301_500,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
  {
    label: '501+',
    value: TeamSizeAnswers.o501,
    next: ProfilingQuestionKeysEnum.TIME_IN_BUSINESS,
  },
]

// ------------------------------------------------------
// ------------------- DOWN-LINE ------------------------
// ------------------------------------------------------
export const getProfilingDownlineHerbalife = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_HERBALIFE,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]

export const getProfilingDownlineFarmasi = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_FARMASI,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]

export const getProfilingDownlineYounique = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_YOUNIQUE,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]

export const getProfilingDownlineArbonne = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_ARBONNE,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]

export const getProfilingDownlineNuSkin = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_NU_SKIN,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]

export const getProfilingDownlineItWorks = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_IT_WORKS,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineModere = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_MODERE,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineJuicePlus = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_JUICE_PLUS,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlinePamperedChef = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_PAMPERED_CHEF,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineXyngular = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_XYNGULAR,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineOriflame = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_ORIFLAME,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlinePruvit = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_PRUVIT,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineMaryKey = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_MARY_KAY,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineSeneGence = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_SENE_GENCE,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineMonat = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_MONAT,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineDoTerra = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_DO_TERRA,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineLimelife = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_LIMELIFE,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineMelaleuca = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_MELALEUCA,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineScentsy = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_SCENTSY,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlinePureRomance = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_PURE_ROMANCE,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineTranont = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_TRANONT,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineTupeerware = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_TUPEERWARE,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineColorStreet = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_COLOR_STREET,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]
export const getProfilingDownlineThirtyOneGifts = (
  translations: TFunction,
): OptionProp[] => [
  {
    label: translations('profiling_downline_yes'),
    value: answerLeader,
    next: ProfilingQuestionKeysEnum.PROFILING_RANK_THIRTY_ONE_GIFTS,
  },
  { label: translations('profiling_downline_no'), value: 'member' },
]

// ------------------------------------------------------
// ----------------------- RANK -------------------------
// ------------------------------------------------------
export const getRankOptionsHerbalife = (): OptionProp[] => [
  {
    label: 'Distributor',
    value: RankAnswersHerbalife.distributor,
  },
  {
    label: 'Senior Consultant',
    value: RankAnswersHerbalife.seniorConsultant,
  },
  {
    label: 'Qualified Producer ',
    value: RankAnswersHerbalife.qualifiedProducer,
  },
  {
    label: 'Supervisor',
    value: RankAnswersHerbalife.supervisor,
  },
  {
    label: 'World Team',
    value: RankAnswersHerbalife.worldTeam,
  },
  {
    label: 'Global Expansion Team',
    value: RankAnswersHerbalife.globalExpansionTeam,
  },
  {
    label: 'Millionaire',
    value: RankAnswersHerbalife.millionaire,
  },
  {
    label: "President's Team",
    value: RankAnswersHerbalife.president,
  },
  {
    label: '15k President’s Team',
    value: RankAnswersHerbalife.president15,
  },
  {
    label: '20k President’s Team',
    value: RankAnswersHerbalife.president20,
  },
  {
    label: '30k President’s Team',
    value: RankAnswersHerbalife.president30,
  },
  {
    label: '40k President’s Team',
    value: RankAnswersHerbalife.president40,
  },
  {
    label: '50k President’s Team',
    value: RankAnswersHerbalife.president50,
  },
  {
    label: '60k President’s Team',
    value: RankAnswersHerbalife.president60,
  },
  {
    label: '70k President’s Team',
    value: RankAnswersHerbalife.president70,
  },
  {
    label: '80k President’s Team',
    value: RankAnswersHerbalife.president80,
  },
  {
    label: '90k President’s Team',
    value: RankAnswersHerbalife.president90,
  },
  {
    label: 'Executive President’s Team',
    value: RankAnswersHerbalife.executivePresident,
  },
  {
    label: 'Senior Executive President’s Team',
    value: RankAnswersHerbalife.seniorExecutivePresident,
  },
  {
    label: 'International Executive President’s Team',
    value: RankAnswersHerbalife.internationalExecutivePresident,
  },
  {
    label: 'Chief Executive President’s Team',
    value: RankAnswersHerbalife.chiefExecutivePresident,
  },
  {
    label: "Chairman's Club",
    value: RankAnswersHerbalife.chairman,
  },
  {
    label: 'Founder’s Circle',
    value: RankAnswersHerbalife.founder,
  },
]

export const getRankOptionsFarmasi = (): OptionProp[] => [
  {
    label: 'Beauty Influencer',
    value: RankAnswersFarmasi.beautyInfluencer,
  },
  {
    label: 'Manager',
    value: RankAnswersFarmasi.manager,
  },
  {
    label: 'Bronze Manager',
    value: RankAnswersFarmasi.bronzeManager,
  },
  {
    label: 'Golden Manager',
    value: RankAnswersFarmasi.goldenManager,
  },
  {
    label: 'Platinum Manager ',
    value: RankAnswersFarmasi.platinumManager,
  },
  {
    label: 'Emerald Manager',
    value: RankAnswersFarmasi.emeraldManager,
  },
  {
    label: 'Diamond Manager',
    value: RankAnswersFarmasi.diamondManager,
  },
  {
    label: 'Vice President Manager',
    value: RankAnswersFarmasi.vicePresidentManager,
  },
  {
    label: 'President Manager',
    value: RankAnswersFarmasi.presidentManager,
  },
  {
    label: 'Boss Manager',
    value: RankAnswersFarmasi.bossManager,
  },
  {
    label: 'Executive Boss Manager',
    value: RankAnswersFarmasi.executiveBossManager,
  },
]

export const getRankOptionsYounique = (): OptionProp[] => [
  {
    label: 'Brand Ambassador',
    value: RankAnswersYounique.brandAmbassador,
  },
  {
    label: 'Brand Specialist',
    value: RankAnswersYounique.brandSpecialist,
  },
  {
    label: 'Senior Brand Specialist',
    value: RankAnswersYounique.seniorBrandSpecialist,
  },
  {
    label: 'Brand Manager',
    value: RankAnswersYounique.brandManager,
  },
  {
    label: 'Senior Brand Manager',
    value: RankAnswersYounique.seniorBrandManager,
  },
  {
    label: 'Brand Director',
    value: RankAnswersYounique.brandDirector,
  },
  {
    label: 'Senior Brand Director',
    value: RankAnswersYounique.seniorBrandDirector,
  },
  {
    label: 'Brand Vice President',
    value: RankAnswersYounique.brandVicePresident,
  },
  {
    label: 'Brand Executive',
    value: RankAnswersYounique.brandExecutive,
  },
  {
    label: 'Presidential Brand Executive',
    value: RankAnswersYounique.presidentialBrandExecutive,
  },
  {
    label: 'Global Brand Executive',
    value: RankAnswersYounique.globalBrandExecutive,
  },
  {
    label: 'Chief Brand Executive',
    value: RankAnswersYounique.chiefBrandExecutive,
  },
]

export const getRankOptionsArbonne = (): OptionProp[] => [
  {
    label: 'Independent Consultant',
    value: RankAnswersArbonne.independentConsultant,
  },
  {
    label: 'District Manager',
    value: RankAnswersArbonne.districtManager,
  },
  {
    label: 'Area Manager',
    value: RankAnswersArbonne.areaManager,
  },
  {
    label: 'Regional Vice President',
    value: RankAnswersArbonne.regionalVicePresident,
  },
  {
    label: 'National Vice President',
    value: RankAnswersArbonne.nationalVicePresident,
  },
]

export const getRankOptionsNuSkin = (): OptionProp[] => [
  {
    label: 'Member',
    value: RankAnswersNuSkin.member,
  },
  {
    label: 'Brand Affiliate',
    value: RankAnswersNuSkin.brandAffiliate,
  },
  {
    label: 'Qualifying Brand Representative',
    value: RankAnswersNuSkin.qualifyingBrandRepresentative,
  },
  {
    label: 'Brand Representative',
    value: RankAnswersNuSkin.brandRepresentative,
  },
  {
    label: 'Brand Partner',
    value: RankAnswersNuSkin.brandPartner,
  },
  {
    label: 'Senior Brand Partner',
    value: RankAnswersNuSkin.seniorBrandPartner,
  },
  {
    label: 'Executive Brand Partner',
    value: RankAnswersNuSkin.executiveBrandPartner,
  },
  {
    label: 'Brand Director',
    value: RankAnswersNuSkin.brandDirector,
  },
  {
    label: 'Senior Brand Director',
    value: RankAnswersNuSkin.seniorBrandDirector,
  },
  {
    label: 'Executive Brand Director',
    value: RankAnswersNuSkin.executiveBrandDirector,
  },
  {
    label: 'Presidential Director',
    value: RankAnswersNuSkin.presidentialDirector,
  },
]

export const getRankOptionsItWorks = (): OptionProp[] => [
  {
    label: 'Partner',
    value: RankAnswersItWorks.partner,
  },
  {
    label: 'Executive',
    value: RankAnswersItWorks.executive,
  },
  {
    label: 'Ruby',
    value: RankAnswersItWorks.ruby,
  },
  {
    label: 'Emerald',
    value: RankAnswersItWorks.emerald,
  },
  {
    label: 'Diamond',
    value: RankAnswersItWorks.diamond,
  },
  {
    label: 'Double Diamond',
    value: RankAnswersItWorks.doubleDiamond,
  },
  {
    label: 'Triple Diamond',
    value: RankAnswersItWorks.tripleDiamond,
  },
  {
    label: 'Presidential Diamond',
    value: RankAnswersItWorks.presidentialDiamond,
  },
  {
    label: 'Ambassador Diamond',
    value: RankAnswersItWorks.ambassadorDiamond,
  },
]

export const getRankOptionsModere = (): OptionProp[] => [
  {
    label: 'Consultant',
    value: RankAnswersModere.consultant,
  },
  {
    label: 'Senior Consultant',
    value: RankAnswersModere.seniorConsultant,
  },
  {
    label: 'Team Leader',
    value: RankAnswersModere.teamLeader,
  },
  {
    label: 'Senior Team Leader',
    value: RankAnswersModere.seniorTeamLeader,
  },
  {
    label: 'Director 1',
    value: RankAnswersModere.director1,
  },
  {
    label: 'Director 2',
    value: RankAnswersModere.director2,
  },
  {
    label: 'Director 3',
    value: RankAnswersModere.director3,
  },
  {
    label: 'Elite 1',
    value: RankAnswersModere.elite1,
  },
  {
    label: 'Elite 2',
    value: RankAnswersModere.elite2,
  },
  {
    label: 'Elite 3',
    value: RankAnswersModere.elite3,
  },
]

export const getRankOptionsJuicePlus = (): OptionProp[] => [
  {
    label: 'Partner',
    value: RankAnswersJuicePlus.partner,
  },
  {
    label: 'Partner Plus',
    value: RankAnswersJuicePlus.partnerPlus,
  },
  {
    label: 'Qualifying Sales Coordinator',
    value: RankAnswersJuicePlus.qualifyingSalesCoordinator,
  },
  {
    label: 'Sales Coordinator',
    value: RankAnswersJuicePlus.salesCoordinator,
  },
  {
    label: 'Qualifying Senior Sales Coordinator',
    value: RankAnswersJuicePlus.qualifyingSeniorSalesCoordinator,
  },
  {
    label: 'Senior Sales Coordinator',
    value: RankAnswersJuicePlus.seniorSalesCoordinator,
  },
  {
    label: 'Qualifying National Marketing Director',
    value: RankAnswersJuicePlus.qualifyingNationalMarketingDirector,
  },
  {
    label: 'National Marketing Director',
    value: RankAnswersJuicePlus.nationalMarketingDirector,
  },
  {
    label: 'International Marketing Director',
    value: RankAnswersJuicePlus.internationalMarketingDirector,
  },
  {
    label: 'Executive Marketing Director',
    value: RankAnswersJuicePlus.executiveMarketingDirector,
  },
  {
    label: 'Presidential Marketing Director',
    value: RankAnswersJuicePlus.presidentialMarketingDirector,
  },
  {
    label: 'Presidential Marketing Director Plus',
    value: RankAnswersJuicePlus.presidentialMarketingDirectorPlus,
  },
]

export const getRankOptionsPamperedChef = (): OptionProp[] => [
  {
    label: 'Consultant',
    value: RankAnswersPamperedChef.consultant,
  },
  {
    label: 'Senior Consultant',
    value: RankAnswersPamperedChef.seniorConsultant,
  },
  {
    label: 'Team Leader',
    value: RankAnswersPamperedChef.teamLeader,
  },
  {
    label: 'Director',
    value: RankAnswersPamperedChef.director,
  },
  {
    label: 'Advanced Director',
    value: RankAnswersPamperedChef.advancedDirector,
  },
  {
    label: 'Senior Director',
    value: RankAnswersPamperedChef.seniorDirector,
  },
  {
    label: 'Executive Director',
    value: RankAnswersPamperedChef.executiveDirector,
  },
  {
    label: 'Senior Executive Director',
    value: RankAnswersPamperedChef.seniorExecutiveDirector,
  },
  {
    label: 'National Executive Director',
    value: RankAnswersPamperedChef.nationalExecutiveDirector,
  },
]

export const getRankOptionsXyngular = (): OptionProp[] => [
  {
    label: 'Partner',
    value: RankAnswersXyngular.partner,
  },
  {
    label: 'Manager',
    value: RankAnswersXyngular.manager,
  },
  {
    label: 'Silver Manager',
    value: RankAnswersXyngular.silverManager,
  },
  {
    label: 'Gold Manager',
    value: RankAnswersXyngular.goldManager,
  },
  {
    label: 'Platinum Manager',
    value: RankAnswersXyngular.platinumManager,
  },
  {
    label: 'Director',
    value: RankAnswersXyngular.director,
  },
  {
    label: 'Silver Director',
    value: RankAnswersXyngular.silverDirector,
  },
  {
    label: 'Gold Director',
    value: RankAnswersXyngular.goldDirector,
  },
  {
    label: 'Platinum Director',
    value: RankAnswersXyngular.platinumDirector,
  },
  {
    label: 'Executive',
    value: RankAnswersXyngular.executive,
  },
  {
    label: 'Silver Executive',
    value: RankAnswersXyngular.silverExecutive,
  },
  {
    label: 'Gold Executive',
    value: RankAnswersXyngular.goldExecutive,
  },
  {
    label: 'Platinum Executive',
    value: RankAnswersXyngular.platinumExecutive,
  },
  {
    label: 'Ambassador',
    value: RankAnswersXyngular.ambassador,
  },
  {
    label: 'Silver Ambassador',
    value: RankAnswersXyngular.silverAmbassador,
  },
  {
    label: 'Gold Ambassador',
    value: RankAnswersXyngular.goldAmbassador,
  },
  {
    label: 'Platinum Ambassador',
    value: RankAnswersXyngular.platinumAmbassador,
  },
]

export const getRankOptionsOriflame = (): OptionProp[] => [
  {
    label: 'Brand Partner',
    value: RankAnswersOriflame.brandPartner,
  },
  {
    label: 'Brand Manager',
    value: RankAnswersOriflame.brandManager,
  },
  {
    label: 'Senior Manager',
    value: RankAnswersOriflame.seniorManager,
  },
  {
    label: 'Director',
    value: RankAnswersOriflame.director,
  },
  {
    label: 'Senior Director',
    value: RankAnswersOriflame.seniorDirector,
  },
  {
    label: 'Gold Director',
    value: RankAnswersOriflame.goldDirector,
  },
  {
    label: 'Senior Gold Director',
    value: RankAnswersOriflame.seniorGoldDirector,
  },
  {
    label: 'Sapphire Director',
    value: RankAnswersOriflame.sapphireDirector,
  },
  {
    label: 'Diamond Director',
    value: RankAnswersOriflame.diamondDirector,
  },
  {
    label: 'Senior Diamond Director',
    value: RankAnswersOriflame.seniorDiamondDirector,
  },
  {
    label: 'Double Diamond Director',
    value: RankAnswersOriflame.doubleDiamondDirector,
  },
  {
    label: 'Executive Director',
    value: RankAnswersOriflame.executiveDirector,
  },
  {
    label: 'Gold Executive Director',
    value: RankAnswersOriflame.goldExecutiveDirector,
  },
  {
    label: 'Sapphire Executive Director',
    value: RankAnswersOriflame.sapphireExecutiveDirector,
  },
  {
    label: 'Diamond Executive Director',
    value: RankAnswersOriflame.diamondExecutiveDirector,
  },
  {
    label: 'Presidential Director',
    value: RankAnswersOriflame.presidentialDirector,
  },
  {
    label: 'Senior Presidential Director',
    value: RankAnswersOriflame.seniorPresidentialDirector,
  },
  {
    label: 'Gold Presidential Director',
    value: RankAnswersOriflame.goldPresidentialDirector,
  },
  {
    label: 'Sapphire Presidential Director',
    value: RankAnswersOriflame.sapphirePresidentialDirector,
  },
  {
    label: 'Diamond Presidential Director',
    value: RankAnswersOriflame.diamondPresidentialDirector,
  },
]

export const getRankOptionsPruvit = (): OptionProp[] => [
  {
    label: 'R1 Pruver',
    value: RankAnswersPruvit.R1Pruver,
  },
  {
    label: 'R2 Pruver',
    value: RankAnswersPruvit.R2Pruver,
  },
  {
    label: 'R3 Pruver',
    value: RankAnswersPruvit.R3Pruver,
  },
  {
    label: 'R4 Pruver',
    value: RankAnswersPruvit.R4Pruver,
  },
  {
    label: 'R5 Pruver',
    value: RankAnswersPruvit.R5Pruver,
  },
  {
    label: 'R6 Champion',
    value: RankAnswersPruvit.R6Champion,
  },
  {
    label: 'R7 100K Champion',
    value: RankAnswersPruvit.R7100KChampion,
  },
  {
    label: 'R8 250K Champion',
    value: RankAnswersPruvit.R8250KChampion,
  },
  {
    label: 'R9 750K Champion',
    value: RankAnswersPruvit.R9750KChampion,
  },
  {
    label: 'R10 1M Champion',
    value: RankAnswersPruvit.R101MChampion,
  },
  {
    label: 'R11 Legend',
    value: RankAnswersPruvit.R11Legend,
  },
]

export const getRankOptionsMaryKay = (): OptionProp[] => [
  {
    label: 'Consultant',
    value: RankAnswersMaryKay.consultant,
  },
  {
    label: 'Senior Consultant',
    value: RankAnswersMaryKay.seniorConsultant,
  },
  {
    label: 'Star Team Builder',
    value: RankAnswersMaryKay.starTeamBuilder,
  },
  {
    label: 'Team Leader',
    value: RankAnswersMaryKay.teamLeader,
  },
  {
    label: 'Elite Team Leader',
    value: RankAnswersMaryKay.eliteTeamLeader,
  },
  {
    label: 'Sales Director',
    value: RankAnswersMaryKay.salesDirector,
  },
  {
    label: 'Senior Sales Director',
    value: RankAnswersMaryKay.seniorSalesDirector,
  },
  {
    label: 'Future Executive Senior',
    value: RankAnswersMaryKay.futureExecutiveSenior,
  },
  {
    label: 'Executive Senior Director',
    value: RankAnswersMaryKay.executiveSeniorDirector,
  },
  {
    label: 'Elite Executive Senior',
    value: RankAnswersMaryKay.eliteExecutiveSenior,
  },
  {
    label: 'National Sales Director',
    value: RankAnswersMaryKay.nationalSalesDirector,
  },
  {
    label: 'Senior National Sales Director',
    value: RankAnswersMaryKay.seniorNationalSalesDirector,
  },
  {
    label: 'Executive National Sales Director',
    value: RankAnswersMaryKay.executiveNationalSalesDirector,
  },
  {
    label: 'Elite Executive National Sales Director',
    value: RankAnswersMaryKay.eliteExecutiveNationalSalesDirector,
  },
]

export const getRankOptionsSeneGence = (): OptionProp[] => [
  {
    label: 'Distributor',
    value: RankAnswersSeneGence.distributor,
  },
  {
    label: 'Maiden',
    value: RankAnswersSeneGence.maiden,
  },
  {
    label: 'Royal',
    value: RankAnswersSeneGence.royal,
  },
  {
    label: 'Lady',
    value: RankAnswersSeneGence.lady,
  },
  {
    label: 'Countess',
    value: RankAnswersSeneGence.countess,
  },
  {
    label: 'Dutchess',
    value: RankAnswersSeneGence.dutchess,
  },
  {
    label: 'Princess',
    value: RankAnswersSeneGence.princess,
  },
  {
    label: 'Crown Princess',
    value: RankAnswersSeneGence.crownPrincess,
  },
  {
    label: 'Queen',
    value: RankAnswersSeneGence.queen,
  },
  {
    label: 'Empress',
    value: RankAnswersSeneGence.empress,
  },
  {
    label: 'Monarch',
    value: RankAnswersSeneGence.monarch,
  },
  {
    label: 'Majesty',
    value: RankAnswersSeneGence.majesty,
  },
  {
    label: 'Highness',
    value: RankAnswersSeneGence.highness,
  },
]

export const getRankOptionsMonat = (): OptionProp[] => [
  {
    label: 'Market Partner',
    value: RankAnswersMonat.marketPartner,
  },
  {
    label: 'Associate Market Builder',
    value: RankAnswersMonat.associateMarketBuilder,
  },
  {
    label: 'Market Builder',
    value: RankAnswersMonat.marketBuilder,
  },
  {
    label: 'Managing Market Builder',
    value: RankAnswersMonat.managingMarketBuilder,
  },
  {
    label: 'Associate Market Mentor',
    value: RankAnswersMonat.associateMarketMentor,
  },
  {
    label: 'Market Mentor',
    value: RankAnswersMonat.marketMentor,
  },
  {
    label: 'Managing Market Mentor',
    value: RankAnswersMonat.managingMarketMentor,
  },
  {
    label: 'Associate Executive Director',
    value: RankAnswersMonat.associateExecutiveDirector,
  },
  {
    label: 'Executive Director',
    value: RankAnswersMonat.executiveDirector,
  },
  {
    label: 'Senior Executive Director',
    value: RankAnswersMonat.seniorExecutiveDirector,
  },
]

export const getRankOptionsDoTerra = (): OptionProp[] => [
  {
    label: 'Wellness Advocate',
    value: RankAnswersDoTerra.wellnessAdvocate,
  },
  {
    label: 'Manager',
    value: RankAnswersDoTerra.manager,
  },
  {
    label: 'Director',
    value: RankAnswersDoTerra.director,
  },
  {
    label: 'Executive',
    value: RankAnswersDoTerra.executive,
  },
  {
    label: 'Elite',
    value: RankAnswersDoTerra.elite,
  },
  {
    label: 'Premier',
    value: RankAnswersDoTerra.premier,
  },
  {
    label: 'Silver',
    value: RankAnswersDoTerra.silver,
  },
  {
    label: 'Gold',
    value: RankAnswersDoTerra.gold,
  },
  {
    label: 'Platinum',
    value: RankAnswersDoTerra.platinum,
  },
  {
    label: 'Diamond',
    value: RankAnswersDoTerra.diamond,
  },
  {
    label: 'Blue Diamond',
    value: RankAnswersDoTerra.blueDiamond,
  },
  {
    label: 'Presidential Diamond',
    value: RankAnswersDoTerra.presidentialDiamond,
  },
]

export const getRankOptionsLimelife = (): OptionProp[] => [
  {
    label: 'Beauty Guide',
    value: RankAnswersLimelife.beautyGuide,
  },
  {
    label: 'Star Beauty Guide',
    value: RankAnswersLimelife.starBeautyGuide,
  },
  {
    label: 'Senior Beauty Guide',
    value: RankAnswersLimelife.seniorBeautyGuide,
  },
  {
    label: 'Director',
    value: RankAnswersLimelife.director,
  },
  {
    label: 'Lead Director',
    value: RankAnswersLimelife.leadDirector,
  },
  {
    label: 'Star Director',
    value: RankAnswersLimelife.starDirector,
  },
  {
    label: 'Senior Director',
    value: RankAnswersLimelife.seniorDirector,
  },
  {
    label: 'Executive Director',
    value: RankAnswersLimelife.executiveDirector,
  },
  {
    label: 'Lead Executive Director',
    value: RankAnswersLimelife.leadExecutiveDirector,
  },
  {
    label: 'Star Executive Director',
    value: RankAnswersLimelife.starExecutiveDirector,
  },
  {
    label: 'Senior Executive Director',
    value: RankAnswersLimelife.seniorExecutiveDirector,
  },
]

export const getRankOptionsMelaleuca = (): OptionProp[] => [
  {
    label: 'Marketing Executive',
    value: RankAnswersMelaleuca.marketingExecutive,
  },
  {
    label: 'Marketing Director',
    value: RankAnswersMelaleuca.marketingDirector,
  },
  {
    label: 'Senior Marketing Director',
    value: RankAnswersMelaleuca.seniorMarketingDirector,
  },
  {
    label: 'Executive Director',
    value: RankAnswersMelaleuca.executiveDirector,
  },
  {
    label: 'National Director',
    value: RankAnswersMelaleuca.nationalDirector,
  },
  {
    label: 'Corporate Director',
    value: RankAnswersMelaleuca.corporateDirector,
  },
  {
    label: 'Presidential Director',
    value: RankAnswersMelaleuca.presidentialDirector,
  },
]

export const getRankOptionsScentsy = (): OptionProp[] => [
  {
    label: 'Essential Consultant',
    value: RankAnswersScentsy.essentialConsultant,
  },
  {
    label: 'Certified Consultant',
    value: RankAnswersScentsy.certifiedConsultant,
  },
  {
    label: 'Lead Consultant',
    value: RankAnswersScentsy.leadConsultant,
  },
  {
    label: 'Star Consultant',
    value: RankAnswersScentsy.starConsultant,
  },
  {
    label: 'Superstar Consultant',
    value: RankAnswersScentsy.superstarConsultant,
  },
  {
    label: 'Director',
    value: RankAnswersScentsy.director,
  },
  {
    label: 'Star Director',
    value: RankAnswersScentsy.starDirector,
  },
  {
    label: 'Superstar Director',
    value: RankAnswersScentsy.superstarDirector,
  },
]

export const getRankOptionsPureRomance = (): OptionProp[] => [
  {
    label: 'Partner',
    value: RankAnswersPureRomance.partner,
  },
  {
    label: 'Advanced Partner',
    value: RankAnswersPureRomance.advancedPartner,
  },
  {
    label: 'Senior Partner',
    value: RankAnswersPureRomance.seniorPartner,
  },
  {
    label: 'Associate Director',
    value: RankAnswersPureRomance.associateDirector,
  },
  {
    label: 'Director',
    value: RankAnswersPureRomance.director,
  },
  {
    label: 'Advanced Director',
    value: RankAnswersPureRomance.advancedDirector,
  },
  {
    label: 'Senior Director',
    value: RankAnswersPureRomance.seniorDirector,
  },
  {
    label: 'National Director',
    value: RankAnswersPureRomance.nationalDirector,
  },
  {
    label: 'Senior National Director',
    value: RankAnswersPureRomance.seniorNationalDirector,
  },
  {
    label: 'Executive Director',
    value: RankAnswersPureRomance.executiveDirector,
  },
  {
    label: 'Senior Executive Director',
    value: RankAnswersPureRomance.seniorExecutiveDirector,
  },
]

export const getRankOptionsTranont = (): OptionProp[] => [
  {
    label: 'Quartz',
    value: RankAnswersTranont.quartz,
  },
  {
    label: 'Onyx',
    value: RankAnswersTranont.onyx,
  },
  {
    label: 'Jade',
    value: RankAnswersTranont.jade,
  },
  {
    label: 'Opal',
    value: RankAnswersTranont.opal,
  },
  {
    label: 'Pearl',
    value: RankAnswersTranont.pearl,
  },
  {
    label: 'Ruby',
    value: RankAnswersTranont.ruby,
  },
  {
    label: 'Sapphire',
    value: RankAnswersTranont.sapphire,
  },
  {
    label: 'Emerald',
    value: RankAnswersTranont.emerald,
  },
  {
    label: 'Diamond',
    value: RankAnswersTranont.diamond,
  },
  {
    label: 'Black Diamond',
    value: RankAnswersTranont.blackDiamond,
  },
  {
    label: 'Red Diamond',
    value: RankAnswersTranont.redDiamond,
  },
  {
    label: 'Blue Diamond',
    value: RankAnswersTranont.blueDiamond,
  },
  {
    label: 'Executive Blue Diamond',
    value: RankAnswersTranont.executiveBlueDiamond,
  },
  {
    label: 'Presidential Blue Diamond',
    value: RankAnswersTranont.presidentialBlueDiamond,
  },
]

export const getRankOptionsTupeerware = (): OptionProp[] => [
  {
    label: 'Consultant',
    value: RankAnswersTupeerware.consultant,
  },
  {
    label: 'Manager',
    value: RankAnswersTupeerware.manager,
  },
  {
    label: 'Star Manager',
    value: RankAnswersTupeerware.starManager,
  },
  {
    label: 'Executive Manager',
    value: RankAnswersTupeerware.executiveManager,
  },
  {
    label: 'Director',
    value: RankAnswersTupeerware.director,
  },
  {
    label: 'Star Director',
    value: RankAnswersTupeerware.starDirector,
  },
  {
    label: '2 Star Director',
    value: RankAnswersTupeerware.twoStarDirector,
  },
  {
    label: '3 Star Director',
    value: RankAnswersTupeerware.threeStarDirector,
  },
  {
    label: '5 Star Director',
    value: RankAnswersTupeerware.fiveStarDirector,
  },
  {
    label: 'Executive Director',
    value: RankAnswersTupeerware.executiveDirector,
  },
  {
    label: 'Star Executive Director',
    value: RankAnswersTupeerware.starExecutiveDirector,
  },
  {
    label: 'Presidential Director',
    value: RankAnswersTupeerware.presidentialDirector,
  },
]

export const getRankOptionsColorStreet = (): OptionProp[] => [
  {
    label: 'Stylist',
    value: RankAnswersColorStreet.stylist,
  },
  {
    label: 'Qualified Stylist',
    value: RankAnswersColorStreet.qualifiedStylist,
  },
  {
    label: 'Bronze Stylist',
    value: RankAnswersColorStreet.bronzeStylist,
  },
  {
    label: 'Silver Stylist',
    value: RankAnswersColorStreet.silverStylist,
  },
  {
    label: 'Gold Stylist',
    value: RankAnswersColorStreet.goldStylist,
  },
  {
    label: 'Platinum Stylist',
    value: RankAnswersColorStreet.platinumStylist,
  },
  {
    label: 'Bronze Leader',
    value: RankAnswersColorStreet.bronzeLeader,
  },
  {
    label: 'Silver Leader',
    value: RankAnswersColorStreet.silverLeader,
  },
  {
    label: 'Gold Leader',
    value: RankAnswersColorStreet.goldLeader,
  },
  {
    label: 'Platinum Leader',
    value: RankAnswersColorStreet.platinumLeader,
  },
  {
    label: 'Silver Executive',
    value: RankAnswersColorStreet.silverExecutive,
  },
  {
    label: 'Gold Executive',
    value: RankAnswersColorStreet.goldExecutive,
  },
  {
    label: 'Platinum Executive',
    value: RankAnswersColorStreet.platinumExecutive,
  },
  {
    label: 'Topaz National',
    value: RankAnswersColorStreet.topazNational,
  },
  {
    label: 'Sapphire National',
    value: RankAnswersColorStreet.sapphireNational,
  },
  {
    label: 'Ruby National',
    value: RankAnswersColorStreet.rubyNational,
  },
  {
    label: 'Emerald National',
    value: RankAnswersColorStreet.emeraldNational,
  },
  {
    label: 'Diamond National',
    value: RankAnswersColorStreet.diamondNational,
  },
]

export const getRankOptionsThirtyOneGifts = (): OptionProp[] => [
  {
    label: 'Consultant',
    value: RankAnswersThirtyOneGifts.consultant,
  },
  {
    label: 'Director',
    value: RankAnswersThirtyOneGifts.director,
  },
  {
    label: 'Senior Director',
    value: RankAnswersThirtyOneGifts.seniorDirector,
  },
  {
    label: 'Executive Director',
    value: RankAnswersThirtyOneGifts.executiveDirector,
  },
]
