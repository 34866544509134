import { AppState } from '../../app/models/appState'

export function setTheme(
  state: AppState,
  boardId: string,
  pageId: string,
  themeId: string,
): AppState {
  return {
    ...state,
    folders: {
      ...state.folders,
      [boardId]: {
        ...state.folders[boardId],
        [pageId]: {
          ...state.folders[boardId][pageId],
          themeId,
        },
      },
    },
  }
}
