import { TPageActions } from 'actions/page'
import { AppState } from '../../app/models/appState'
import { PAGE_ACTION_TYPE } from '../../actions'
import { setTheme } from './pagesReducer'

export function pagesReducer(
  state: AppState,
  action: TPageActions,
): AppState | undefined {
  switch (action.type) {
    case PAGE_ACTION_TYPE.SET_THEME: {
      return setTheme(state, action.boardId, action.pageId, action.themeId)
    }

    default: {
      return undefined
    }
  }
}
