import { MutableRefObject } from 'react'

export enum ScrollAreaContainer {
  FOLDER = 'folder',
  PAGE = 'page',
  PAGE_BUILDER_THEME = 'page_builder_theme',
}

export interface ScrollAreaRef {
  area: ScrollAreaContainer
  ref: MutableRefObject<HTMLDivElement>
  endRef: MutableRefObject<HTMLDivElement>
}

export interface ScrollAreasContextProps {
  addArea: (
    area: ScrollAreaContainer,
    ref: MutableRefObject<HTMLDivElement>,
    endRef: MutableRefObject<HTMLDivElement>,
  ) => void
  removeArea: (area: ScrollAreaContainer) => void
  getArea: (area: ScrollAreaContainer) => ScrollAreaRef | undefined
}
