import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, ThemePreview, theme } from 'boards-web-ui'

import useThemes from '@features/editorPages/hooks/useThemes'
import InfoTipBanner from '@features/editorPages/components/InfoTipBanner'
import usePageEditorEvents from '@features/analytics/usePageEditorEvents'

import ScrollArea from '@ui/components/Scroll/ScrollArea'
import { ScrollAreaContainer } from '@ui/models/ScrollArea'
import { ThemeResponse } from '_firebase'

import styles from './ThemePicker.module.css'

const DEFAULT_THEME_ID = '1'

interface Props {
  currentThemeId?: string
  setPageTheme: (theme: ThemeResponse) => void
}
const ThemePicker: FC<Props> = ({ currentThemeId, setPageTheme }) => {
  const { t } = useTranslation()
  const themesData = useThemes()
  const [currentTheme, setCurrentTheme] = useState(
    currentThemeId || DEFAULT_THEME_ID,
  )
  const { pageBuilderThemeClick } = usePageEditorEvents()

  const { visibleThemes, isBranded } = useMemo(() => {
    const allThemes = themesData.data || []
    const visibleThemesMemoized = allThemes.filter(
      (themeData) => !themeData.theme.properties?.isBranded,
    )
    const pageTheme = allThemes.find(({ id }) => id === currentTheme)
    const isBrandedMemoized = pageTheme?.theme.properties?.isBranded || false

    return {
      visibleThemes: visibleThemesMemoized,
      isBranded: isBrandedMemoized,
    }
  }, [themesData.data, currentTheme])

  const handleThemeSelection = (themeData: ThemeResponse) => {
    pageBuilderThemeClick(
      themeData.id,
      themeData.theme.properties?.thumbnail?.title?.content || '',
    )
    setCurrentTheme(themeData.id)
    setPageTheme(themeData)
  }

  return (
    <ScrollArea
      className={styles.ScrollArea}
      area={ScrollAreaContainer.PAGE_BUILDER_THEME}
    >
      <Grid alignItems="center">
        <div className={styles.Root}>
          {isBranded && (
            <InfoTipBanner
              icon="⚠️"
              description={t('page_builder_theme_brand_theme_indication')}
            />
          )}
          <div className={styles.FlexContainer}>
            {visibleThemes.map((themeData) => (
              <div key={themeData.id} className={styles.FlexItem}>
                <theme.UiThemeProvider
                  theme={{ webPageTheme: themeData.theme }}
                >
                  <ThemePreview
                    selected={currentTheme === themeData.id}
                    disabled={isBranded}
                    onClick={() => handleThemeSelection(themeData)}
                  />
                </theme.UiThemeProvider>
              </div>
            ))}
          </div>
        </div>
      </Grid>
    </ScrollArea>
  )
}

export default ThemePicker
