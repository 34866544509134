import React, { FC, ReactComponentElement, ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CheckoutFormProduct,
  DraggableList,
  getCurrencySymbol,
} from 'boards-web-ui'

import getCurrencyInputLabel from '@features/checkout/utils/getCurrencyInputLabel'

import AddButton from '../../../components/AddButton'

import { useCheckout, useProductsInputRefs } from '../hooks'
import CheckoutProduct from './CheckoutProduct'
import styles from './CheckoutProducts.module.css'

type Props = {
  onAfterAddProduct?: (product?: CheckoutFormProduct) => void
}
const CheckoutProducts: FC<Props> = ({ onAfterAddProduct }) => {
  const { t } = useTranslation()

  const {
    products,
    currency,
    onProductsReOrder,
    onProductChange,
    onProductAdd,
    onProductDelete,
  } = useCheckout({
    onAfterAddProduct: (product) => {
      setFocusOnProductFieldRef(product)
      if (onAfterAddProduct) {
        onAfterAddProduct()
      }
    },
  })

  const { addRef, findCurrentRefByActiveElement, setFocusOnProductFieldRef } =
    useProductsInputRefs(products)

  const productsReorderHandler = (items?: ReactNode[]) => {
    const productsInNewOrder: Array<CheckoutFormProduct> | undefined =
      items?.map((productComponent) => {
        const productProps = (
          productComponent as ReactComponentElement<typeof CheckoutProduct>
        ).props
        return {
          ...productProps.product,
        }
      })

    setFocusOnProductFieldRef()
    onProductsReOrder(productsInNewOrder || [])
  }

  const renderProducts = useMemo(() => {
    const inputPriceLabel = getCurrencyInputLabel(
      t('product_price_label'),
      getCurrencySymbol(currency),
    )

    return products.map((product: CheckoutFormProduct) => (
      <CheckoutProduct
        autoFocus={products.length === 1 && !product?.label}
        key={product.id}
        data-key={product.id}
        product={product}
        titlePlaceholder={t('product_name_label')}
        descriptionPlaceholder={t('product_description_label')}
        priceLabel={inputPriceLabel}
        quantityLabel={t('product_max_quantity_label')}
        currency={currency}
        onDelete={() => onProductDelete(product)}
        titleRef={(refElement: HTMLDivElement) =>
          addRef(product.id, 'title', refElement)
        }
        descriptionRef={(refElement: HTMLDivElement) =>
          addRef(product.id, 'description', refElement)
        }
        onChange={(updatedProduct) =>
          onProductChange({
            ...product,
            ...updatedProduct,
          })
        }
      />
    ))
  }, [products, currency, onProductChange, onProductDelete, t, addRef])

  return (
    <>
      <DraggableList
        className={styles.Products}
        onReOrderStart={findCurrentRefByActiveElement}
        onReOrderEnd={productsReorderHandler}
      >
        {renderProducts}
      </DraggableList>
      <AddButton onClick={() => onProductAdd()}>
        {t('action_add_product')}
      </AddButton>
    </>
  )
}

export default React.memo(CheckoutProducts)
