import { useSearchParams } from 'react-router-dom'

export enum TabsEnum {
  CONTENT = 'content',
  THEME_PICKER = 'theme_picker',
}
const useTabState = (defaultTab: TabsEnum) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const isValidTab = (tab: string | null): tab is TabsEnum =>
    !!tab && Object.values(TabsEnum).includes(tab as TabsEnum)

  const activeTab = isValidTab(searchParams.get('tab'))
    ? (searchParams.get('tab') as TabsEnum)
    : defaultTab

  const setActiveTab = (newTab: string) => {
    setSearchParams({ tab: newTab })
  }

  return { activeTab, setActiveTab }
}

export default useTabState
