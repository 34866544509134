import React from 'react'

import useBulk from '@hooks/bulk/useBulk'
import { TooltipPositions } from '@ui/models/tooltip'
import Tooltip, { TooltipContent } from '@ui/components/Tooltip'

import { ButtonIcon, Grid, RedoIcon, UndoIcon } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import useUndoRedo from '../hooks/useUndoRedo'
import useToolbarTranslations from '../hooks/useToolbarTranslations'

type Props = {
  className?: string
}
const UndoRedo: React.FC<Props> = ({ className }) => {
  const { undo, redo, isDisabled } = useUndoRedo()
  const { undoAction, redoAction } = useToolbarTranslations()

  const { isSelectionModeEnabled } = useBulk()

  const selectionActive = isSelectionModeEnabled

  return (
    <Grid>
      <Tooltip
        className={className}
        position={TooltipPositions.BOTTOM}
        data={
          selectionActive ? '' : <TooltipContent>{undoAction()}</TooltipContent>
        }
      >
        <ButtonIcon
          round
          variant="purple-transparent"
          name="undo"
          size={38}
          disabled={!undo || isDisabled}
          onClick={undo}
        >
          <IconWrapper>
            <UndoIcon />
          </IconWrapper>
        </ButtonIcon>
      </Tooltip>

      <Tooltip
        className={className}
        data={
          selectionActive ? '' : <TooltipContent>{redoAction()}</TooltipContent>
        }
      >
        <ButtonIcon
          round
          variant="purple-transparent"
          name="redo"
          size={38}
          disabled={!redo || isDisabled}
          onClick={redo}
        >
          <IconWrapper>
            <RedoIcon />
          </IconWrapper>
        </ButtonIcon>
      </Tooltip>
    </Grid>
  )
}

export default UndoRedo
