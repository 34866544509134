import { useState } from 'react'
import { SUPPORTED_CURRENCY } from 'boards-web-ui'

import { DEFAULT_CURRENCY } from '../constants'

type Props = {
  defaultCurrency?: string
  supportedCurrencies?: string[]
}
const DEFAULT_PROPS: Required<Props> = {
  defaultCurrency: DEFAULT_CURRENCY,
  supportedCurrencies: SUPPORTED_CURRENCY as string[],
}
const useCheckoutCurrency = (props?: Props) => {
  const mergedProps: Required<Props> = {
    ...DEFAULT_PROPS,
    ...props,
  }

  const [currency, setCurrency] = useState<string>(mergedProps.defaultCurrency)
  const onCurrencyChange = (newCurrency: string) => setCurrency(newCurrency)

  return {
    currency,
    currencies: mergedProps.supportedCurrencies,
    onCurrencyChange,
  } as const
}

export default useCheckoutCurrency
