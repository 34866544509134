import { AddPageData } from '@features/toolbar/models'
import optimisticUI, { OptimisticApiCall } from 'actions/optimisticUI'
import { ActionCreator, ActionCreatorOptimistic } from './type-helpers'

export const enum ACTION_TYPE {
  ADD_PAGE = 'ADD_PAGE',
  SET_THEME = 'SET_THEME',
}

interface IAddPage {
  board: string
  folder: string
  key: string
  properties: AddPageData
}

const addPageOptimistic = (
  payload: IAddPage,
  apiCall: OptimisticApiCall,
  onError?: () => void,
) =>
  optimisticUI.createOptimisticAction({
    type: ACTION_TYPE.ADD_PAGE,
    payload,
    apiCall,
    onError,
  })

const setPageTheme = (boardId: string, pageId: string, themeId: string) => ({
  type: ACTION_TYPE.SET_THEME,
  boardId,
  pageId,
  themeId,
})

const actions = {
  addPageOptimistic,
  setPageTheme,
} as const

export type TPageActions =
  | ActionCreator<typeof setPageTheme, ACTION_TYPE.SET_THEME>
  | ActionCreatorOptimistic<typeof addPageOptimistic, ACTION_TYPE.ADD_PAGE>

export default actions
