import { useCallback } from 'react'
import useBoard from '../../../hooks/useBoard'
import { useLogEvents } from './useLogEvents'
import { EventCategories } from './models/logEvents'
import { PageTabTypeEnum, TargetScreens } from './models/sourceEvents'

const usePageEditorEvents = () => {
  const { boardId, folderId, isPageBoard } = useBoard()
  const { trigger } = useLogEvents()

  const viewEditorScreen = useCallback(() => {
    trigger(EventCategories.APP_SCREEN_VIEW, {
      screen: TargetScreens.PAGE,
      board_id: boardId,
      key_id: folderId,
    })
  }, [boardId, folderId, trigger])

  const viewPageEditorScreen = useCallback(
    (isNew?: boolean) => {
      trigger(EventCategories.APP_PAGE_EDITOR_VIEW, {
        screen: TargetScreens.PAGE,
        board_id: boardId,
        key_id: folderId,
        pages_in_homescreen: isPageBoard.toString(),
        state: isNew ? 'new' : 'edit',
      })
    },
    [trigger, boardId, folderId, isPageBoard],
  )

  const viewInsertContentDialogEvent = useCallback(() => {
    trigger(EventCategories.APP_INSERT_CONTENT_DIALOG_VIEW, {
      screen: TargetScreens.PAGE,
      board_id: boardId,
      key_id: folderId,
    })
  }, [boardId, folderId, trigger])

  const pageBuilderTabClick = useCallback(
    (tab: PageTabTypeEnum) => {
      trigger(EventCategories.APP_PAGE_HEADER_EDIT_TYPE_CLICK, {
        edit_type: tab,
      })
    },
    [trigger],
  )

  const pageBuilderThemeClick = useCallback(
    (themeId: string, themeName: string) => {
      trigger(EventCategories.APP_PAGE_THEME_CLICK, {
        theme_id: themeId,
        theme_name: themeName,
      })
    },
    [trigger],
  )

  return {
    viewEditorScreen,
    viewInsertContentDialogEvent,
    viewPageEditorScreen,
    pageBuilderTabClick,
    pageBuilderThemeClick,
  }
}

export default usePageEditorEvents
