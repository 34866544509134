import { FormResponseSpreadsheetStatuses } from 'boards-web-ui'
import {
  FirebaseTimestamp,
  ReverseTrialUserFlags,
} from '@features/reverseTrial/models/reverseTrialModels'

export interface UserProfilePlanFeatures {
  advancedForms: boolean
  analytics: boolean
  announcements: boolean
  coeditors: boolean
  copyReceivedContent: boolean
  contentRestrictions: boolean
  saveImages: boolean
  unbrandedPages: boolean
  uploadAudio: boolean
  uploadVideo: boolean
  webApp: boolean
  forms: boolean
  checkout: boolean
  themes: boolean
}

export type UserProfileFeatureLimit = number | 'unlimited'

export interface UserProfilePlanLimits {
  audios: UserProfileFeatureLimit
  boards: UserProfileFeatureLimit
  messages: UserProfileFeatureLimit
  shares: UserProfileFeatureLimit
  videos: UserProfileFeatureLimit
}

export interface UserProfilePlanActivation {
  expires: FirebaseTimestamp
  isReverseTrial: boolean
  plan: string
}

export interface UserProfilePlan {
  features: UserProfilePlanFeatures
  limits: UserProfilePlanLimits
  activations: UserProfilePlanActivation[]
  monetized: boolean
}

export interface UserProfileUIState {
  buttonsHintShown: boolean
  boardsLimitationsAnnouncementShown?: boolean
  chromeExtensionBannerShown: boolean | null
  checkoutHintShown?: boolean
  formsHintShown: boolean
  freemiumMessagesHintShown: boolean
  installMobileAppHintShown: boolean
  monetizationBannerShown: boolean
  pagesHintShown: boolean
  pagesBoardHiddenFromChrome?: boolean
  videoHintShown: boolean
  showEndOfReverseTrialScreen: boolean
  themePickerTabHintShown: boolean
}

export type UserProfileFeature = {
  android: string
  ios: string
  web: string
}

export enum ProfileFeatures {
  CHECKOUT = 'checkout',
  GOOGLE_SHEETS = 'googleSheets',
  TEMPLATES = 'templates',
  PROFILE_SECTION_VARIATIONS = 'profileVariation',
}

export type UserProfileFeatures = {
  [ProfileFeatures.CHECKOUT]?: UserProfileFeature
  [ProfileFeatures.GOOGLE_SHEETS]?: UserProfileFeature
  [ProfileFeatures.TEMPLATES]?: UserProfileFeature
  [ProfileFeatures.PROFILE_SECTION_VARIATIONS]: UserProfileFeature
}

export type UserProfile = {
  country?: string
  language?: string
  timezoneOffset?: number
  profileIsLoaded: boolean
  activeBoards: string[]
  name: string | undefined
  shortcut: string
  formResponseEmail: string | undefined
  formResponseSpreadsheet: string | undefined
  formResponseSpreadsheetStatus?: FormResponseSpreadsheetStatuses
  googleAuthorization: {
    access_token: string | undefined
    expiry_date: number | undefined
    refresh_token: string | undefined
    scope: string | undefined
    token_type: string | undefined
    credentials?: {
      access_token?: string
      expiry_date?: number
      refresh_token?: string
      scope?: string
      token_type?: string
    }
    status?: 'authorized'
  }
  payPalAuthorization?: {
    merchantId?: string
    status: 'authorized' | 'revoked' | string
    merchantCountry: string
    merchantPaymentsReceivable: boolean
    merchantPrimaryCurrency: string
    merchantPrimaryEmailConfirmed: boolean
  }
  uiState: UserProfileUIState
  plan: UserProfilePlan
  usage: {
    boards: number
    audios: number
    messages: number
    shares: number
    videos: number
  }
  store: {
    accountToken?: string
    countryCode?: string
  }
  clients?: string[]
  segmentIds?: string[]
  preferences?: {
    emails?: {
      offers?: boolean
      tips?: boolean
      product?: boolean
    }
  }
  features?: UserProfileFeatures
  reverseTrial: ReverseTrialUserFlags
}
