import { FC, useEffect, useState } from 'react'
import {
  isPageNodeCheckoutForm,
  isPageNodeForm,
  TabPanel,
  TabProvider,
  TPageNode,
} from 'boards-web-ui'
import clsx from 'clsx'

import usePageEditorEvents from '@features/analytics/usePageEditorEvents'
import {
  ICreateOrUpdateNode,
  INodeProps,
} from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { SelectAction } from '@features/pickers'

import { useSelectorFactory } from '@hooks/useSelectorFactory'
import useRouterParams from '@hooks/useRouterParams'
import useProfileFeatureFlag from '@hooks/useProfileFeatureFlag'
import { ProfileFeatures } from '@models/UserProfile'

import { usePagePremiumLimitationMode } from '@features/editorPages/hooks/usePagePremiumLimitationMode'
import useFormIntegrationStatusState from '@features/forms/hooks/useFormIntegrationStatusState'
import PageBuilderTools from '@features/editorPages/components/PageBuilderTools'
import ThemePicker from '@features/editorPages/components/ThemePicker'
import useTabState, { TabsEnum } from '@features/editorPages/hooks/useTabState'
import { PageTabTypeEnum } from '@features/analytics/models/sourceEvents'
import { ThemeResponse } from '_firebase'
import { Folder, Node } from '../../../models'
import { selectMissedPageFields } from '../../../../selectors'

import useTipBanner from '../hooks/useTipBanner'
import PageTitle from './PageTitle'
import PagePreview from './PagePreview'
import PagePreviewHead from './PagePreviewHead'
import ContentHead from './ContentHead'

import styles from './PageEditor.module.css'

interface Props {
  title: string
  folder: Folder
  updateTitle: (title: string) => void
  createNode: ICreateOrUpdateNode
  updateNode: (id: string, node: INodeProps) => void
  deleteNode?: (node: Node) => void
  onClickDone: () => void
  selectAction: SelectAction
  setPageTheme: (theme: ThemeResponse) => void
  isNew?: boolean
}

const PageEditor: FC<Props> = ({
  title,
  folder,
  updateTitle,
  createNode,
  updateNode,
  deleteNode,
  onClickDone,
  selectAction,
  setPageTheme,
  isNew,
}) => {
  const { activeTab, setActiveTab } = useTabState(TabsEnum.CONTENT)
  const { viewPageEditorScreen, pageBuilderTabClick } = usePageEditorEvents()
  const { isTipBannerVisible } = useTipBanner(folder)
  const { boardId } = useRouterParams()
  const { onGetFormIntegrationStatus } = useFormIntegrationStatusState(
    boardId,
    folder.id || '',
  )
  const [containerClassName, setContainerClassName] = useState<string>(
    clsx(styles.Container, styles.WithMarginShift),
  )

  useEffect(() => {
    viewPageEditorScreen(isNew)
  }, [viewPageEditorScreen, isNew])

  useEffect(() => {
    setContainerClassName(clsx(styles.Container))
  }, [])

  useEffect(() => {
    folder?.content?.forEach((nodeItem) => {
      if (
        (isPageNodeCheckoutForm(nodeItem as TPageNode) ||
          isPageNodeForm(nodeItem as TPageNode)) &&
        (!nodeItem.integrationState ||
          !Object.values(nodeItem.integrationState).length)
      ) {
        onGetFormIntegrationStatus(nodeItem.id)
      }
    })
  }, [folder, onGetFormIntegrationStatus])

  const { getPremiumPreviewPageFolder } = usePagePremiumLimitationMode()
  const { showPremiumPreviewPageLimitationModeBanner, previewPageFolder } =
    getPremiumPreviewPageFolder(folder)

  const { isFeatureAvailable: isCheckoutFeatureAvailable } =
    useProfileFeatureFlag(ProfileFeatures.CHECKOUT)
  const missedPageProperties = useSelectorFactory(
    selectMissedPageFields,
    boardId,
    folder?.id,
    isCheckoutFeatureAvailable,
  )

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    const parsedTab = Object.values(TabsEnum).find(
      (tab) => tab === newValue,
    ) as TabsEnum

    if (parsedTab) {
      setActiveTab(parsedTab)

      const tabToPageTabMap: Record<TabsEnum, PageTabTypeEnum> = {
        [TabsEnum.CONTENT]: PageTabTypeEnum.CONTENT,
        [TabsEnum.THEME_PICKER]: PageTabTypeEnum.THEMES,
      }

      pageBuilderTabClick(tabToPageTabMap[parsedTab])
    }
  }

  if (!folder) return null

  const shouldShowIndicators =
    !isTipBannerVisible &&
    !showPremiumPreviewPageLimitationModeBanner &&
    !!Object.keys(missedPageProperties).length

  return (
    <TabProvider value={activeTab} onChange={handleTabChange}>
      <div className={containerClassName}>
        <div className={styles.Title}>
          <PageTitle
            folder={folder}
            newTitle={title}
            setNewTitle={updateTitle}
            onDoneClick={onClickDone}
          />
        </div>

        <div className={styles.ContentHead}>
          <ContentHead />
        </div>

        <div className={styles.PreviewHead}>
          <PagePreviewHead />
        </div>

        <div className={styles.Content}>
          <TabPanel value={TabsEnum.CONTENT}>
            <PageBuilderTools
              folder={folder}
              shouldShowIndicators={shouldShowIndicators}
              showPremiumPreviewPageLimitationModeBanner={
                showPremiumPreviewPageLimitationModeBanner
              }
              createNode={createNode}
              updateNode={updateNode}
              selectAction={selectAction}
              deleteNode={deleteNode}
            />
          </TabPanel>
          <TabPanel value={TabsEnum.THEME_PICKER}>
            <ThemePicker
              currentThemeId={folder.themeId}
              setPageTheme={setPageTheme}
            />
          </TabPanel>
        </div>

        <div className={styles.Preview}>
          {previewPageFolder ? (
            <PagePreview
              folder={{
                ...(previewPageFolder as Folder),
                content: previewPageFolder.content as Folder['content'],
              }}
            />
          ) : null}
        </div>
      </div>
    </TabProvider>
  )
}

export default PageEditor
