import { useSelector } from 'react-redux'
import { useAuth } from '@hooks/useAuth'
import { AppState } from '../app/models/appState'

export const useSharedFolder = () => {
  const { user } = useAuth()
  const allFolders = useSelector((state: AppState) => state.folders)
  const sharedFolderState = useSelector(
    (state: AppState) => state.app.sharedFolder,
  )

  const foundCompositeBoardId = Object.keys(allFolders).find((x) => {
    return x.includes(sharedFolderState.boardId)
  })
  const isSharedFolderExistsInFolders = Boolean(foundCompositeBoardId)
  const showSharedFolder = Boolean(
    sharedFolderState.shortcutId?.length > 0 &&
      sharedFolderState.boardId?.length > 0 &&
      (sharedFolderState?.folderId?.length ?? 0) > 0 &&
      user &&
      !isSharedFolderExistsInFolders,
  )

  return {
    sharedFolderState,
    isSharedFolderExistsInFolders,
    foundCompositeBoardId,
    showSharedFolder,
  } as const
}
