import { Role, RoleAnalytics } from '../app/models'

export const mapPermissionToAnalyticsBoardRole = ({
  role,
  isOwnBoard,
  isWriteAllowed,
}: {
  role: Role
  isOwnBoard: boolean
  isWriteAllowed: boolean
}) => {
  if (isOwnBoard) {
    return RoleAnalytics.OWNER
  }
  return role === Role.WRITE && isWriteAllowed
    ? RoleAnalytics.EDITOR
    : RoleAnalytics.MEMBER
}
