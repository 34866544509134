import { Folder } from 'app/models'
import {
  isPageNodeCheckoutForm,
  isPageNodeForm,
  NodeType,
  TPageNode,
  TPageNodeCheckoutForm,
  TPageNodeForm,
} from 'boards-web-ui'

export type IsMissedFormProperties = {
  paymentProvider?: boolean
  responseGoogleSheetUrl?: boolean
  responsesEmail?: boolean
}

type findMissedFieldsInFormProps = {
  formNode: TPageNodeForm | TPageNodeCheckoutForm

  isCheckoutFeatureAvailable: boolean

  isGoogleAccountConnected?: boolean
  isPayPalAccountConnected?: boolean
  merchantPaymentsReceivable?: boolean
  merchantPrimaryEmailConfirmed?: boolean
}
export const findMissedFieldsInForm = ({
  formNode,

  isCheckoutFeatureAvailable,
  isPayPalAccountConnected,

  merchantPaymentsReceivable,
  merchantPrimaryEmailConfirmed,
}: findMissedFieldsInFormProps): IsMissedFormProperties | undefined => {
  const missedProperties: IsMissedFormProperties = {}

  if (
    formNode.form.type === NodeType.CHECKOUT &&
    isCheckoutFeatureAvailable &&
    (!isPayPalAccountConnected ||
      !formNode.form.paymentProviders?.length ||
      !merchantPaymentsReceivable ||
      !merchantPrimaryEmailConfirmed)
  ) {
    missedProperties.paymentProvider = true
  }

  if (!formNode.form.email && !formNode.form.encryptedEmail) {
    missedProperties.responsesEmail = true
  }

  return Object.keys(missedProperties).length ? missedProperties : undefined
}

type Props = {
  page: Folder
  isCheckoutFeatureAvailable: boolean
}
export type IsMissedPageProperties = {
  [id: string]: IsMissedFormProperties
}
const findMissingFieldsInPageForms = ({
  page,
  isCheckoutFeatureAvailable,
}: Props): IsMissedPageProperties => {
  return page.content
    .filter(
      (node) =>
        isPageNodeForm(node as TPageNode) ||
        isPageNodeCheckoutForm(node as TPageNode),
    )
    .reduce((res, node) => {
      if (!node.integrationState) {
        return {}
      }

      const missedProperties = findMissedFieldsInForm({
        formNode: node as unknown as TPageNodeForm | TPageNodeCheckoutForm,

        isCheckoutFeatureAvailable,

        isPayPalAccountConnected:
          node?.integrationState?.payPalAuthorization?.status === 'authorized',
        merchantPaymentsReceivable:
          node?.integrationState?.payPalAuthorization
            ?.merchantPaymentsReceivable,
        merchantPrimaryEmailConfirmed:
          node?.integrationState?.payPalAuthorization
            ?.merchantPrimaryEmailConfirmed,
      })

      return missedProperties
        ? {
            ...res,
            [node.id]: {
              ...missedProperties,
            },
          }
        : { ...res }
    }, {})
}

export default findMissingFieldsInPageForms
