import { TFunction } from 'i18next'
import { QuestionsProps, QuestionType } from 'boards-web-ui'
import { ProfilingQuestionKeysEnum } from '@features/profiling/models'
import {
  getBrandOptions,
  getIndustryOptions,
  getProfilingDownline,
  getProfilingDownlineArbonne,
  getProfilingDownlineColorStreet,
  getProfilingDownlineDoTerra,
  getProfilingDownlineFarmasi,
  getProfilingDownlineHerbalife,
  getProfilingDownlineItWorks,
  getProfilingDownlineJuicePlus,
  getProfilingDownlineLimelife,
  getProfilingDownlineMaryKey,
  getProfilingDownlineMelaleuca,
  getProfilingDownlineModere,
  getProfilingDownlineMonat,
  getProfilingDownlineNuSkin,
  getProfilingDownlineOriflame,
  getProfilingDownlinePamperedChef,
  getProfilingDownlinePruvit,
  getProfilingDownlinePureRomance,
  getProfilingDownlineScentsy,
  getProfilingDownlineSeneGence,
  getProfilingDownlineSize,
  getProfilingDownlineThirtyOneGifts,
  getProfilingDownlineTranont,
  getProfilingDownlineTupeerware,
  getProfilingDownlineXyngular,
  getProfilingDownlineYounique,
  getRankOptionsArbonne,
  getRankOptionsColorStreet,
  getRankOptionsDoTerra,
  getRankOptionsFarmasi,
  getRankOptionsHerbalife,
  getRankOptionsItWorks,
  getRankOptionsJuicePlus,
  getRankOptionsLimelife,
  getRankOptionsMaryKay,
  getRankOptionsMelaleuca,
  getRankOptionsModere,
  getRankOptionsMonat,
  getRankOptionsNuSkin,
  getRankOptionsOriflame,
  getRankOptionsPamperedChef,
  getRankOptionsPruvit,
  getRankOptionsPureRomance,
  getRankOptionsScentsy,
  getRankOptionsSeneGence,
  getRankOptionsThirtyOneGifts,
  getRankOptionsTranont,
  getRankOptionsTupeerware,
  getRankOptionsXyngular,
  getRankOptionsYounique,
  getTeamSizeOptions,
  getTimeInBusinessOptions,
  getTypeOptions,
} from './profilingQuestionOptions'

export const getQuestionsData = (translations: TFunction): QuestionsProps => {
  return {
    [ProfilingQuestionKeysEnum.INDUSTRY]: {
      type: QuestionType.SELECT,
      title: translations('user_profiling_question_industry'),
      options: getIndustryOptions(translations),
    },
    [ProfilingQuestionKeysEnum.BRAND_TEXT]: {
      type: QuestionType.TEXT,
      next: ProfilingQuestionKeysEnum.TYPE,
      title: translations(
        'profiling_industry_question_organization_name_title',
      ),
      placeholder: translations(
        'profiling_industry_question_organization_name_placeholder',
      ),
      options: [],
    },
    [ProfilingQuestionKeysEnum.BRAND]: {
      type: QuestionType.BRAND,
      title: translations(
        'user_profiling_question_organization',
      ),
      options: getBrandOptions(translations),
    },
    [ProfilingQuestionKeysEnum.BRAND_SELECT_TEXT]: {
      type: QuestionType.TEXT,
      next: ProfilingQuestionKeysEnum.PROFILING_DOWNLINE,
      title: translations(
        'profiling_industry_question_organization_name_title',
      ),

      placeholder: translations(
        'profiling_industry_question_organization_name_placeholder',
      ),

      options: [],
    },
    [ProfilingQuestionKeysEnum.TIME_IN_BUSINESS]: {
      type: QuestionType.SELECT,
      title: translations('profiling_time_in_bussines_title'),
      options: getTimeInBusinessOptions(translations),
    },
    [ProfilingQuestionKeysEnum.TYPE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_who_are_you_question_title'),
      options: getTypeOptions(translations),
    },
    [ProfilingQuestionKeysEnum.TEAM_SIZE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_team_size_title'),
      options: getTeamSizeOptions(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownline(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SIZE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline_size'),
      options: getProfilingDownlineSize(),
    },

    // ------------------------------------------------------
    // ------------------- DOWN-LINE ------------------------
    // ------------------------------------------------------
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_HERBALIFE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineHerbalife(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_FARMASI]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineFarmasi(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_YOUNIQUE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineYounique(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_ARBONNE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineArbonne(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_NU_SKIN]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineNuSkin(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_IT_WORKS]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineItWorks(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MODERE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineModere(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_JUICE_PLUS]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineJuicePlus(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_PAMPERED_CHEF]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlinePamperedChef(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_XYNGULAR]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineXyngular(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_ORIFLAME]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineOriflame(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_PRUVIT]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlinePruvit(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MARY_KAY]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineMaryKey(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SENE_GENCE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineSeneGence(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MONAT]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineMonat(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_DO_TERRA]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineDoTerra(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_LIMELIFE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineLimelife(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_MELALEUCA]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineMelaleuca(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_SCENTSY]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineScentsy(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_PURE_ROMANCE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlinePureRomance(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_TRANONT]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineTranont(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_TUPEERWARE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineTupeerware(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_COLOR_STREET]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineColorStreet(translations),
    },
    [ProfilingQuestionKeysEnum.PROFILING_DOWNLINE_THIRTY_ONE_GIFTS]: {
      type: QuestionType.SELECT,
      title: translations('profiling_downline'),
      options: getProfilingDownlineThirtyOneGifts(translations),
    },

    // ------------------------------------------------------
    // ----------------------- RANK -------------------------
    // ------------------------------------------------------
    [ProfilingQuestionKeysEnum.PROFILING_RANK_HERBALIFE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsHerbalife(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_FARMASI]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsFarmasi(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_YOUNIQUE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsYounique(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_ARBONNE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsArbonne(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_NU_SKIN]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsNuSkin(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_IT_WORKS]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsItWorks(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_MODERE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsModere(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_JUICE_PLUS]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsJuicePlus(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_PAMPERED_CHEF]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsPamperedChef(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_XYNGULAR]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsXyngular(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_ORIFLAME]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsOriflame(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_PRUVIT]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsPruvit(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_MARY_KAY]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsMaryKay(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_SENE_GENCE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsSeneGence(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_MONAT]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsMonat(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_DO_TERRA]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsDoTerra(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_LIMELIFE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsLimelife(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_MELALEUCA]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsMelaleuca(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_SCENTSY]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsScentsy(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_PURE_ROMANCE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsPureRomance(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_TRANONT]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsTranont(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_TUPEERWARE]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsTupeerware(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_COLOR_STREET]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsColorStreet(),
    },
    [ProfilingQuestionKeysEnum.PROFILING_RANK_THIRTY_ONE_GIFTS]: {
      type: QuestionType.SELECT,
      title: translations('profiling_rank_question'),
      options: getRankOptionsThirtyOneGifts(),
    },
  }
}
