import { getRootFolderId } from '@helpers/NodeIdGenerator'
import { ContentPickersEnum } from '@features/pickers'
import { AppState } from '../../app/models/appState'
import { Board, Folder } from '../../app/models'
import * as FolderManagement from '../FolderManagement'
import { setFolderPathReducer } from '../foldersReducers/foldersReducers'

export function setBoardReducer(
  state: AppState,
  boardId: string,
  board: Board,
): AppState {
  return {
    ...state,
    boards: {
      ...state.boards,
      [boardId]: board,
    },
  }
}

// TODO https://heybliss.atlassian.net/browse/BLISS-10770
export function createBoardReducer(
  state: AppState,
  owner: string,
  bid: string,
  properties: { title?: string; icon?: string; key?: string },
  picker?: ContentPickersEnum,
  extraProps?: {
    type: 'pages'
    isPageBoard: true
  },
): AppState {
  const boardId = `${owner}-${bid}`
  const rootFolderId = getRootFolderId(boardId)

  const board = {
    id: bid,
    isOwn: true,
    role: 'own',
    action: {},
    rootFolderId,
    key: properties.key,
    icon: properties.icon,
    title: properties.title,
    isActive: true,
    options: {
      checkoutAllowed: true,
      copyAllowed: true,
      formsAllowed: true,
      insightsAllowed: false,
      writeAllowed: false,
      notCountedTowardsLimit: false,
      shareAllowed: true,
      shareFolderAllowed: true,
    },
    ...extraProps,
  }
  const rootFolder = {
    id: rootFolderId,
    icon: properties.icon,
    title: properties.title,
    content: [],
  }

  const pickerStatePath = picker
    ? setFolderPathReducer(state, { picker, boardId, folderId: rootFolderId })
    : {}

  return {
    ...state,
    boards: {
      ...state.boards,
      // TODO is missing the following properties from type Board: receivedContent, summary
      [boardId]: board as unknown as Board,
    },
    folders: FolderManagement.addFolder(
      state.folders,
      boardId,
      // TODO fix this casting type (is missing the following properties from type Folder: shortcut, isPage)
      rootFolder as unknown as Folder,
    ),
    ...pickerStatePath,
  }
}

export function createFakeBoardReducer(
  state: AppState,
  owner: string,
  bid: string,
  properties: { title?: string; icon?: string; key?: string },
): AppState {
  return {
    ...createBoardReducer(state, owner, bid, properties),
    profileInfo: {
      ...state.profileInfo,
      activeBoards: [...state.profileInfo.activeBoards, bid],
      usage: {
        ...state.profileInfo.usage,
        boards: state.profileInfo.usage.boards + 1,
      },
    },
  }
}

export function createPagesBoardReducer(
  state: AppState,
  owner: string,
  bid: string,
  properties: { title?: string; icon?: string; key?: string },
): AppState {
  return createBoardReducer(state, owner, bid, properties, undefined, {
    type: 'pages',
    isPageBoard: true,
  })
}

export function deleteBoardReducer(state: AppState, boardId: string): AppState {
  const { boards } = state
  const board = boards[boardId]
  delete boards[boardId]

  return {
    ...state,
    boards,
    profileInfo: {
      ...state.profileInfo,
      usage: {
        ...state.profileInfo.usage,
        boards: board.isActive
          ? state.profileInfo.usage.boards - 1
          : state.profileInfo.usage.boards,
      },
    },
  }
}

export function removeBoardReducer(state: AppState, boardId: string): AppState {
  const { boards } = state
  delete boards[boardId]

  return {
    ...state,
    boards,
  }
}
