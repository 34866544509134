import { useQuery } from 'react-query'
import { getThemes } from '_firebase'

interface Props {
  onError?: () => void
}
const useThemes = ({ onError }: Props = {}) => {
  const cacheTime = 60 * 60 * 1000

  return useQuery({
    queryKey: ['themes'],
    staleTime: cacheTime,
    cacheTime,
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: getThemes,
    onError,
  })
}

export default useThemes
